import React, { useEffect } from "react";
import useState from "react-usestateref";

import { gsap } from "gsap";
import Header from "./Header";
import apiService from "../core/service/detail";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import { getMethod, postMethod } from "../core/service/common.api";
import ReactPaginate from "react-paginate";
import axios from "axios";
import env from "../core/service/envconfig";
import { Link, useNavigate } from "react-router-dom";

function Landing() {
    const [usdtINRPrice, setUsdtINRPrice] = useState(0);
    const [formValue, setFormValue, formValueref] = useState({
        amount: "",
        receiveToken: "",
        transactionId: "",
        transactionProof: "",
    });
    const navigate = useNavigate();

    const [validationErr, setValidationErr] = useState({});
    const [Bankdeatils, setBankdeatils] = useState({});

    const [proofLoading, setProofLoading] = useState(false);
    const [proofLoading1, setProofLoading1] = useState(false);

    const [proofUrl, setProofUrl] = useState("");
    const [filename, setfilename] = useState("");

    useEffect(() => {
        const getINRPrice = async () => {
            try {
                const response = await axios.get(
                    `https://min-api.cryptocompare.com/data/pricemulti?fsyms=INR&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`
                );
                if (response.data && response.data.INR) {
                    const inrToUsdt = response.data.INR.USDT;
                    const vtxAmount = (1 / env.TTTusdtprice) * inrToUsdt;
                    setUsdtINRPrice(vtxAmount.toFixed(4));
                }
            } catch (error) {
                console.error("Failed to fetch INR to USDT rate:", error);
                showerrorToast("Failed to fetch INR to USDT rate.");
            }
        };

        getINRPrice();
    }, []);

    useEffect(() => {
        const calculateReceiveToken = () => {
            console.log(formValue.amount, "ingikniknenvef");
            if (formValue.amount != "") {
                const calculatedVtxAmount = (
                    formValue.amount == "" ? 0 : formValue.amount * usdtINRPrice
                ).toFixed(4);
                setFormValue((prev) => ({
                    ...prev,
                    receiveToken: calculatedVtxAmount,
                }));
            } else {
                setFormValue((prev) => ({
                    ...prev,
                    receiveToken: "",
                }));
            }
        };

        calculateReceiveToken();
    }, [formValue.amount, usdtINRPrice]);

    const validate = (values) => {
        let errors = {};
        if (!values.amount) {
            errors.amount = "Amount is required!";
        } else {
            errors.amount = "";
        }
        if (!values.transactionId) {
            errors.transactionId = "Transaction ID is required!";
        } else if (
            values.transactionId.length < 11 ||
            values.transactionId.length > 36
        ) {
            errors.transactionId =
                "Transaction ID must be between 11 and 36 characters!";
        } else if (/[^a-zA-Z0-9]/.test(values.transactionId)) {
            errors.transactionId =
                "Transaction ID cannot contain white spaces or special characters!";
        } else {
            errors.transactionId = "";
        }

        if (!values.transactionProof) {
            errors.transactionProof = "Transaction proof is required!";
        } else {
            errors.transactionProof = "";
        }
        setValidationErr(errors);
        return errors;
    };

    const validate1 = (values) => {
        let errors = {};
        if (!values.amount) {
            errors.amount = "Amount is required!";
        } else {
            errors.amount = "";
        }
        // if (!values.receiveToken) {
        //     errors.receiveToken = "Receive Token is required!";
        // } else {
        //     errors.receiveToken = "";
        // }

        setValidationErr(errors);
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "amount") {
            if (value.length <= 10) {
                setFormValue({ ...formValue, [name]: value });
            } else {
            }
        } else {
            setFormValue({ ...formValue, [name]: value });
        }
        validate(formValueref.current);
    };

    useEffect(() => {
        getBank();
    }, []);

    const getBank = async () => {
        try {
            const data = {
                apiUrl: apiService.getbank,
            };

            const resp = await getMethod(data);

            if (resp.status === true) {
                setBankdeatils(resp.data);
            } else {
                setBankdeatils({});
            }
        } catch (err) {
            console.log(err);
        }
    };

    const formSubmit = async () => {
        let errors = validate(formValue);
        formValue.type = 0;

        console.log(errors, "uhujhehei")

        if (errors.amount == '', errors.transactionId == '', errors.transactionProof == '') {
            setProofLoading1(true);
            const data = {
                apiUrl: apiService.fiatdeposit,
                payload: formValue,
            };
            try {
                const resp = await postMethod(data);

                if (resp.status === true) {
                    setFormValue({
                        amount: "",
                        receiveToken: "",
                        transactionId: "",
                        transactionProof: "",
                    });
                    setfilename("");
                    showsuccessToast(resp.Message);
                } else {
                    showerrorToast(resp.Message);
                }
                setProofLoading1(false);
            } catch (err) {
                showerrorToast("Failed to submit transaction. Please try again.");
                setProofLoading(false);
            }
        } else {
            showerrorToast("Please fill in all required fields correctly.");
        }
    };

    const formSubmit1 = async () => {
        let errors = validate1(formValue);
        formValue.type = 1;
        console.log(errors,  "iijniknikj")

        if (errors.amount == "") {
            setProofLoading1(true);
            const data = {
                apiUrl: apiService.fiatdeposit,
                payload: formValue,
            };
            try {
                const resp = await postMethod(data);

                if (resp.status === true) {
                    setFormValue({
                        amount: "",
                        receiveToken: "",
                        transactionId: "",
                        transactionProof: "",
                    });
                    navigate("/contact");

                    showsuccessToast(resp.Message);
                } else {
                    showerrorToast(resp.Message);
                }
                setProofLoading1(false);
            } catch (err) {
                showerrorToast("Failed to submit transaction. Please try again.");
                setProofLoading1(false);
            }
        } else {
            showerrorToast("Please fill in all required fields correctly.");
        }
    };

    const imageUpload = (e) => {
        const val = e.target.files[0];
        if (!val) return;

        console.log(val);

        const fileExtension = val.name.split(".").pop().toLowerCase();
        const file = val.name;
        console.log(file);

        setfilename(file);
        console.log(filename);

        const fileSize = val.size;

        if (!["png", "jpg", "jpeg"].includes(fileExtension)) {
            showerrorToast(
                "File does not support. You must use .png or .jpg or .jpeg"
            );
        } else if (fileSize > 1000000) {
            showerrorToast("Please upload a file smaller than 1 MB");
        } else {
            setProofLoading(true);
            const data = new FormData();
            data.append("file", val);
            data.append("upload_preset", env.upload_preset);
            data.append("cloud_name", env.cloud_name);

            fetch(
                "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
                { method: "post", body: data }
            )
                .then((resp) => resp.json())
                .then((data) => {
                    setProofLoading(false);
                    setProofUrl(data.secure_url);
                    setFormValue({ ...formValue, transactionProof: data.secure_url });
                    validate(formValueref.current);

                })
                .catch((err) => {
                    setProofLoading(false);
                    showerrorToast("Please try again later");
                });
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                showsuccessToast("Copied to clipboard successfully!");
            },
            (err) => {
                showerrorToast("Failed to copy to clipboard: ", err);
            }
        );
    };

    const showsuccessToast = (message) => {
        toast.dismiss();
        toast.success(message);
    };

    const showerrorToast = (message) => {
        toast.dismiss();
        toast.error(message);
    };

    return (
        <div>
            <Header />
            <div className="Buyfiat">
                <div className="container">
                    <div>
                        <div className="buy-card my-5">
                            <ul class="nav nav-tabs">
                                <li class="active_tab">
                                    <a data-toggle="tab" href="#home" className="active">
                                        <button className="order-btn-place">
                                            <span className=""> Manual Deposit</span>
                                        </button>
                                    </a>
                                </li>
                                <li class="active_tab">
                                    <a data-toggle="tab" href="#menu2">
                                        <button className="order-btn-place">
                                            <span className=""> Direct Deposit </span>
                                        </button>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="tab-pane fade in active show">
                                    <div className="row">
                                        <div className="col-lg-6 my-3">
                                            <div className="fiat-left">
                                                <div className="form">
                                                    <div className="input-groups">
                                                        <label>Amount</label>
                                                        <div className="input1">
                                                            <input
                                                                className="input-class"
                                                                placeholder="0.00"
                                                                name="amount"
                                                                value={formValue.amount}
                                                                min="0"
                                                                minLength="0"
                                                                type="Number"
                                                                onChange={handleChange}
                                                            />
                                                            <span>INR</span>
                                                        </div>
                                                        {validationErr.amount && (
                                                            <p className="errorcss">{validationErr.amount}</p>
                                                        )}
                                                    </div>
                                                    <div className="input-groups">
                                                        <label>Receive Token </label>

                                                        <div className="input1">
                                                            <input
                                                                className="input-class"
                                                                placeholder="0.00"
                                                                name="receiveToken"
                                                                value={formValue.receiveToken}
                                                                min="0"
                                                                minLength="0"
                                                                type="Number"
                                                                disabled
                                                            />
                                                            <span>VTX</span>
                                                        </div>
                                                        {validationErr.receiveToken && (
                                                            <p className="errorcss">
                                                                {validationErr.receiveToken}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="input-groups">
                                                        <label>Transaction ID </label>
                                                        <input
                                                            className="input-class"
                                                            placeholder="Enter the transaction ID"
                                                            name="transactionId"
                                                            value={formValue.transactionId}
                                                            type="text"
                                                            maxLength="36"
                                                            onChange={handleChange}
                                                        />
                                                        {validationErr.transactionId && (
                                                            <p className="errorcss">
                                                                {validationErr.transactionId}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="input-groups">
                                                        <label>Transaction Proof</label>
                                                        <div className="input1 input-class">
                                                            <input
                                                                className="opacity-0"
                                                                placeholder="Upload Proof"
                                                                type="file"
                                                                onChange={imageUpload}
                                                            />

                                                            <div className="places">
                                                                {" "}
                                                                {filename == "" ? "Upload Proof" : filename}
                                                            </div>
                                                            <span>
                                                                {proofLoading ? (
                                                                    "Loading"
                                                                ) : (
                                                                    <i className="fa-solid fa-arrow-up-from-bracket yel"></i>
                                                                )}
                                                            </span>
                                                        </div>
                                                        {validationErr.transactionProof && (
                                                            <p className="errorcss">
                                                                {validationErr.transactionProof}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="input-groups">
                                                        {proofLoading1 == true ? (
                                                            <button>Loading</button>
                                                        ) : (
                                                            <button onClick={formSubmit}>Submit</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-3">
                                            <div className="fiat-right">
                                                <div className="forms">
                                                    <div className="head">
                                                        <span>Bank Details</span>

                                                        <div className="warning">
                                                            <p className="Note"> Note: </p>
                                                            <p className="Note text-white">
                                                                {" "}
                                                                Copy the following details into your bank's
                                                                transfer form
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="body">
                                                        <div className="input-groups">
                                                            <label>Bank Name</label>
                                                            <div className="input1">
                                                                <input
                                                                    className="input-class"
                                                                    value={Bankdeatils.bank_name}
                                                                    disabled
                                                                    placeholder="0.00"
                                                                />
                                                                <span>
                                                                    <i
                                                                        className="fa-regular cursor-pointer fa-copy"
                                                                        onClick={() =>
                                                                            copyToClipboard(Bankdeatils.bank_name)
                                                                        }
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="input-groups">
                                                            <label>Account No</label>
                                                            <div className="input1">
                                                                <input
                                                                    className="input-class"
                                                                    value={Bankdeatils.account_no}
                                                                    disabled
                                                                    placeholder="0.00"
                                                                />
                                                                <span>
                                                                    <i
                                                                        className="fa-regular cursor-pointer fa-copy"
                                                                        onClick={() =>
                                                                            copyToClipboard(Bankdeatils.account_no)
                                                                        }
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="input-groups">
                                                            <label>Amount Holder Name</label>
                                                            <div className="input1">
                                                                <input
                                                                    className="input-class"
                                                                    value={Bankdeatils.account_holder_name}
                                                                    disabled
                                                                    placeholder="0.00"
                                                                />
                                                                <span>
                                                                    <i
                                                                        className="fa-regular cursor-pointer fa-copy"
                                                                        onClick={() =>
                                                                            copyToClipboard(
                                                                                Bankdeatils.account_holder_name
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="input-groups">
                                                            <label>IFSC Code</label>
                                                            <div className="input1">
                                                                <input
                                                                    className="input-class"
                                                                    value={Bankdeatils.ifsc_code}
                                                                    disabled
                                                                    placeholder="0.00"
                                                                />
                                                                <span>
                                                                    <i
                                                                        className="fa-regular cursor-pointer fa-copy"
                                                                        onClick={() =>
                                                                            copyToClipboard(Bankdeatils.ifsc_code)
                                                                        }
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu2" class="tab-pane fade">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="fiat-left">
                                                <div className="form">
                                                    <div className="input-groups">
                                                        <label>Amount</label>
                                                        <div className="input1">
                                                            <input
                                                                className="input-class"
                                                                placeholder="0.00"
                                                                name="amount"
                                                                min="0"
                                                                minLength="0"
                                                                type="Number"
                                                                value={formValue.amount}
                                                                onChange={handleChange}
                                                            />
                                                            <span>INR</span>
                                                        </div>
                                                        {validationErr.amount && (
                                                            <p className="errorcss">{validationErr.amount}</p>
                                                        )}
                                                    </div>
                                                    <div className="input-groups">
                                                        <label>Receive Token </label>

                                                        <div className="input1">
                                                            <input
                                                                className="input-class"
                                                                placeholder="0.00"
                                                                min="0"
                                                                minLength="0"
                                                                type="Number"
                                                                name="receiveToken"
                                                                value={formValue.receiveToken}
                                                                disabled
                                                            />
                                                            <span>VTX</span>
                                                        </div>
                                                        {validationErr.receiveToken && (
                                                            <p className="errorcss">
                                                                {validationErr.receiveToken}
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="input-groups">
                                                        {proofLoading1 == true ? (
                                                            <button>Loading</button>
                                                        ) : (
                                                            <button onClick={formSubmit1}>Submit </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LaunchScreen from "./pages/LaunchScreen";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Login from "./pages/Login";
import EmailOTP from "./pages/EmailOTP";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Buy from "./pages/Buy";
import BuyFiat from "./pages/Buyfiat";
import Contact from "./pages/Contact";



import { removeAuthToken } from "../src/core/lib/localStorage";



import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

function App() {

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }


  return (
  <>
        <BrowserRouter>
        {/* <BrowserRouter basename="/testing"> */}
        <ToastContainer />
        <Routes>

          <Route path="/" element={<Landing />} />
          
          <Route path="/landing" element={<Landing />} />
          <Route path="/buy" element={<Buy />} />

        <Route path="/dashboard" element={ <RequireAuth>
          <Dashboard />
        </RequireAuth> } />
        <Route path="/buyfiat" element={<BuyFiat />} />
        <Route path="/contact" element={<Contact />} />



          <Route path="/register" element={<Register />} />
         <Route path="/verify-email" element={<EmailOTP />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />




        </Routes>
      </BrowserRouter>
  </>
  );
}

export default App;

const env = {
//   apiHost: "http://localhost:3033/",
//  frontUrl: "http://localhost:3000/",
  apiHost: "https://vtx.voltrixcrypt.io:3033/",
  frontUrl: "https://vtx.voltrixcrypt.io/",
  upload_preset: "f3l6gwtp",
  cloud_name: "dg2vjjlpm",

  TTTusdtprice: 0.01,


  // testnet
    // stakeContractAddressNew: "0xA1cf0cF1BBBF84eabCb51322442244F78Ed6cF0F",
    // tokenAddres: "0x4eb217e0262f40d570afa511e978038658c940b9",
    // percentage: 1,
    // usdtAddres: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    // BuyAddress: "0x0cca9b107b09fd583b59abdc89425f105ff18c29",
    // rpc_url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // explorerUrl: "https://testnet.bscscan.com/",


    // mainnet
    tokenAddres: "0x55aE36E761ECdc22b81F4bB903AC05cCf0A65eDB",
    percentage: 1,
    usdtAddres: "0x55d398326f99059fF775485246999027B3197955",
    BuyAddress: "0x65580B923b93F3e8ad1895001139Df3B0649c9d7",
    rpc_url: "https://bsc-dataseed1.binance.org/",
    explorerUrl: "https://bscscan.com/",

};

export default env;  

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import Whitepaper from "../assets/whitepaper.pdf"

import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";

import BNBforTokenABI from "./BNBforTokenABI.json";

import env from "../core/service/envconfig";

import {
  removeAuthToken
} from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";

// import { BeatLoader } from 'react-spinners';
// import { WifiLoader } from "react-awesome-loaders";

// import { toast } from "react-toastify";

const drawerWidth = 240;



function Header(props) {

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [ShowLogin,setShowLogin] = useState(false);
  // const [ShowLogin,setShowLogin] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x38,
    name: "Binance Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: "https://bsc-dataseed1.binance.org/",

    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
    coinbasePreference: "eoaOnly",
    auth: {
      email: false, // default to true
      // socials: ['google', 'x', 'github', 'discord', 'apple'],
      showWallets: true, // default to true
      walletFeatures: true, // default to true
    },
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    includeWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    ],
  });

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, isConnected } = useWeb3ModalAccount();

  const [address, setaddress] = useState(null);
  const [balance, setbalance] = useState("false");
  const [active, setactive] = useState("buy");

  const [web3, setWeb3] = useState(null);

  const [userToken, setuserToken] = useState("");

  // Function to disconnect wallet
  async function disconnectWallet() {
    Loading.hourglass("Disconnecting...", {
      messageColor: "#fff",
      svgColor: "#ffc630",
      svgSize: "100px",
      backgroundColor: "#17171a90",
    });
    localStorage.clear();
    setShowLogin(false);
    const provider = new BrowserProvider(walletProvider);
    console.log("Provider:", provider);
    console.log("Provider emit method:", provider?.emit);
    try {
      if (provider && typeof provider?.emit === "function") {
        await disconnect();
      } else {
        console.error("Provider or emit function is not available.");
      }
    } catch (error) {
      console.error("Error occurred while disconnecting:", error);
    }

    setaddress(null);
    initializeWeb3();
    Loading.remove();
  }

  useEffect(() => {
    initializeWeb3();

    var token = localStorage.getItem("user_token");
    setuserToken(token);

  }, []);

  const initializeWeb3 = async () => {
    const storedAddress = localStorage.getItem("userAddress");
    if (storedAddress != null) {
      setaddress(storedAddress);
      setShowLogin(true);
    }
  };

 

  const getbalanceaddress = async () => {
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "fff",
        svgColor: "#ffc630",
        svgSize: "100px",
        backgroundColor: "#17171a90",
      });
      try {
        if (!isConnected) throw Error("User disconnected");

        console.log(walletProvider, "walletProvider")

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        console.log("address", address);
        setaddress(address);
        const balance = await provider.getBalance(address);
        localStorage.setItem("userAddress", address);
        const ethBalance = parseInt(balance) / 1000000000000000000;
        setbalance(ethBalance);
        localStorage.setItem("Balance", ethBalance);
        const bnbProvider = new JsonRpcProvider(env.rpc_url);
        const Tokencontarct = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          bnbProvider
        );
        const tokenBalance = await Tokencontarct.balanceOf(address);
        localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000000000000000);
        localStorage.setItem("wallettype", "wallet-connect");
        initializeWeb3();
        Loading.remove();
        setShowLogin(true);
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  };

  if (isConnected) {
   
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }

  const handleWalletConnect = async () => {
    await open();
  };

  const [dropstatus, setdropstatus] = useState(false);

  const dropdowns = async () => {
    // console.log(dropstatus,"dropstatus")
    if(dropstatus == true){
      setdropstatus(false);
    }else{
      setdropstatus(true);
    }
  };

  const logout = async () => {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#121418", color: "#fff" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
        <div className="landing-header1" style={{textAlign : "left" , paddingLeft : "20px" ,paddingTop : "22px" , paddingBottom : "20px",borderBottom : "1px solid rgba(255, 255, 255, 0.05)"}} >
          <img
            src={require("../images/Logo.png")}
            alt="logo image"
            className="logo-img"
          />
        </div>
        </Link>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to={ userToken != null ? "/buyfiat" : "/buy"}>
              <div className="land_header_signup">
                <span className="land_header_leftmenus"> Buy Voltrix </span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <a href={Whitepaper} target="_blank">
              <div className="land_header_signup">
                <span className="land_header_leftmenus"> Whitepaper </span>
              </div>
            </a>
          </ListItemButton>
        </ListItem>
        {userToken != null ? (
          <>
          <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/dashboard">
              <div className="land_header_signup">
                <span className="land_header_rghtmenu"> Dashboard </span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
        <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
          <Link to="" onClick={logout}>
            <div className="land_header_signup">
              <span className="land_header_rghtmenu"> Logout </span>
            </div>
          </Link>
        </ListItemButton>
      </ListItem>
      </>
        ) : (
          <>
          {
            ShowLogin == true ? ( "" ) : (
            <>
          <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/login">
              <div className="land_header_signup">
                <span className="land_header_rghtmenu"> Login </span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
        <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
          <Link to="/register">
            <div className="land_header_signup">
              <span className="land_header_rghtmenu"> Register </span>
            </div>
          </Link>
        </ListItemButton>
        </ListItem>
        </>
        )}

{address == null ? "" : (
  <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link>
              <div className="land_header_signup">
                <span className="land_header_rghtmenu"> {address.substring(0, 12)} ... </span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>

)

}
        
        

        </>
        )}
         <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
          {address == null ? (
              userToken != "" && userToken != null && userToken != undefined ? "" : 
                    <div
                      className="land_header_conn"
                      onClick={handleWalletConnect}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="img-con"
                        viewBox="0 0 32 32"
                        id="wallet"
                      >
                        <g fill="#17171a" class="color000000 svgShape">
                          <path
                            d="M26.42,9,20.26,3.76a2,2,0,0,0-2.5-.08l-2.67,2L13.41,4a2,2,0,0,0-2.82,0l-5,5.05A3,3,0,0,0,3,12V26a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V12A3,3,0,0,0,26.42,9ZM27,21H25a2,2,0,0,1,0-4h2ZM19,5.28,23.3,9H18.41L16.52,7.11Zm-7,.13L15.59,9H8.41ZM26,27H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H26a1,1,0,0,1,1,1v3H25a4,4,0,0,0,0,8h2v3A1,1,0,0,1,26,27Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                          <path
                            d="M24.29,18.29A1.05,1.05,0,0,0,24,19a1,1,0,1,0,2,0,1,1,0,0,0-1.71-.71Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                        </g>
                      </svg>
                       <span className="con_lan_con">Connect</span>
    
                    
                    </div>
                  ) : (

                    
                 <>
                 <div
                    className="land_header_conn"
                    onClick={() => disconnectWallet()}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="disconnect" viewBox="0 0 24 24" id="logout"><path d="M20.9 11.6c-.1-.1-.1-.2-.2-.3l-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.3 1.3H13c-.6 0-1 .4-1 1s.4 1 1 1h4.6l-1.3 1.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3-3c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#17171a" class="color000000 svgShape"></path><path d="M15.5 18.1c-1.1.6-2.3.9-3.5.9-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C15.1 3.4 13.6 3 12 3c-5 0-9 4-9 9s4 9 9 9c1.6 0 3.1-.4 4.5-1.2.5-.3.6-.9.4-1.4-.3-.4-.9-.6-1.4-.3z" fill="#17171a" class="color000000 svgShape"></path></svg>
                    <span className="con_lan_con">Disconnect</span>
                  </div>
                 
                 
                 </>
                  )}
          </ListItemButton>
          
        </ListItem>
       {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div className="land_header_signup">
                <span className="land_header_leftmenus">Litepaper</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div
                className="lan_had_con"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="img-con"
                  viewBox="0 0 20 20"
                  id="wallet"
                >
                  <path d="M16 6H3.5v-.5l11-.88v.88H16V4c0-1.1-.891-1.872-1.979-1.717L3.98 3.717C2.891 3.873 2 4.9 2 6v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path>
                </svg>
                <span className="con_lan_con">Connect</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <div className="drawer-head">
              <div className="header-res-menu">
                <div className="landing-header1">
                  <Link to="/">
                    <img
                      src={require("../images/Logo.png")}
                      alt="logo"
                      className="logo-img"
                    />
                  </Link>
                </div>
              </div>
              <div className="header-new-main">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}
            >
              <div className="header-res-menu">
                <div className="landing-header1">
                  <Link to="/">
                    <img
                      src={require("../images/Logo.png")}
                      alt="logo"
                      className="logo-img"
                    />
                  </Link>
                </div>
                <div className="landing-header2">
                  {/* <Link to="/">
                    <span className="land_header_leftmenus">Home</span>
                  </Link>
                  <Link to="/">
                    <span className="land_header_leftmenus">About</span>
                  </Link>
                  <Link to="/">
                    <span className="land_header_leftmenus">Tokenomics</span>
                  </Link>
                  <Link to="/">
                    <span className="land_header_leftmenus">Roadmap</span>
                  </Link>
                  <Link to="/">
                    <span className="land_header_leftmenus">Community</span>
                  </Link> */}
                  <Link to={ userToken != null ? "/buyfiat" : "/buy"}> 
                    <span className="land_header_leftmenus">Buy Voltrix</span>
                  </Link>
                  <a href={Whitepaper} target="_blank">
                  <span className="land_header_leftmenus">Whitepaper</span>
                  </a>
                </div>
              </div>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <>
                <div className="header-right-menu">
                  {userToken != null ? (
                    <>
                  <Link to="/dashboard">
                   <div className="land_header_signup">
                     <span className="land_header_rghtmenu">Dashboard</span>
                   </div>
                 </Link>

                  <Link to="" onClick={logout}>
                    <div className="land_header_signup">
                      <span className="land_header_rghtmenu">Logout</span>
                    </div>
                  </Link>
                    </>
                  
                  ) : (
                    <>
                      {
                        ShowLogin == true ? "" : 
                        <>

                   <Link to="/login">
                    <div className="land_header_signup">
                      <span className="land_header_rghtmenu">Login</span>
                    </div>
                  </Link>

                  <Link to="/register">
                  <div className="land_header_signup">
                    <span className="land_header_rghtmenu">Register</span>
                  </div>
                  </Link>
                        </>
                       
                      }
                    
                    </>
                  )}

                  {address == null ? (

                    userToken != "" && userToken != null && userToken != undefined ? "" : 
                    <div
                      className="land_header_conn"
                      onClick={handleWalletConnect}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="img-con"
                        viewBox="0 0 32 32"
                        id="wallet"
                      >
                        <g fill="#17171a" class="color000000 svgShape">
                          <path
                            d="M26.42,9,20.26,3.76a2,2,0,0,0-2.5-.08l-2.67,2L13.41,4a2,2,0,0,0-2.82,0l-5,5.05A3,3,0,0,0,3,12V26a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V12A3,3,0,0,0,26.42,9ZM27,21H25a2,2,0,0,1,0-4h2ZM19,5.28,23.3,9H18.41L16.52,7.11Zm-7,.13L15.59,9H8.41ZM26,27H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H26a1,1,0,0,1,1,1v3H25a4,4,0,0,0,0,8h2v3A1,1,0,0,1,26,27Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                          <path
                            d="M24.29,18.29A1.05,1.05,0,0,0,24,19a1,1,0,1,0,2,0,1,1,0,0,0-1.71-.71Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                        </g>
                      </svg>
                      
                         <span className="con_lan_con">Connect</span>
                      
                    </div>
                  ) : (
                    <div
                      className="land_header_conn"
                      onClick={dropdowns}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="img-con"
                        viewBox="0 0 32 32"
                        id="wallet"
                      >
                        <g fill="#17171a" class="color000000 svgShape">
                          <path
                            d="M26.42,9,20.26,3.76a2,2,0,0,0-2.5-.08l-2.67,2L13.41,4a2,2,0,0,0-2.82,0l-5,5.05A3,3,0,0,0,3,12V26a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V12A3,3,0,0,0,26.42,9ZM27,21H25a2,2,0,0,1,0-4h2ZM19,5.28,23.3,9H18.41L16.52,7.11Zm-7,.13L15.59,9H8.41ZM26,27H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H26a1,1,0,0,1,1,1v3H25a4,4,0,0,0,0,8h2v3A1,1,0,0,1,26,27Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                          <path
                            d="M24.29,18.29A1.05,1.05,0,0,0,24,19a1,1,0,1,0,2,0,1,1,0,0,0-1.71-.71Z"
                            fill="#17171a"
                            class="color000000 svgShape"
                          ></path>
                        </g>
                      </svg> */}
                      <span className="con_lan_con">
                        {address.substring(0, 8)} ...
                        <div className={ dropstatus == true ? "dropdown-content" : "dropdown-content d-none"} onClick={() => disconnectWallet()}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="disconnect" viewBox="0 0 100 100" id="Wallet"><path d="M93 48.627h-.5V34.968c0-4.263-3.157-7.792-7.254-8.398v-3.073c0-4.687-3.813-8.5-8.5-8.5H72.98l-1.983-5.285a1.5 1.5 0 0 0-1.864-.901l-19.201 6.186H10.735c-3.989 0-7.235 3.246-7.235 7.235V82.76c0 4.687 3.813 8.5 8.5 8.5h72c4.687 0 8.5-3.813 8.5-8.5V69.101h.5c1.93 0 3.5-1.57 3.5-3.5V52.127c0-1.929-1.57-3.5-3.5-3.5zM74.106 17.998h2.64c3.032 0 5.5 2.467 5.5 5.5v2.971h-4.961l-.299-.797-2.88-7.674zm-4.33-3 2.437 6.494 1.868 4.977H24.109l44.582-14.362 1.085 2.891zm-59.041 3h29.884l-18.84 6.07-7.453 2.401h-3.591c-2.335 0-4.235-1.9-4.235-4.235s1.9-4.236 4.235-4.236zM89.5 82.76c0 3.033-2.468 5.5-5.5 5.5H12a5.506 5.506 0 0 1-5.5-5.5V28.096c.021.016.046.026.068.042.262.185.535.354.821.504.053.028.109.052.163.079.265.131.538.246.82.344.048.017.094.036.142.052.312.101.633.177.962.235.073.013.147.023.221.034.34.049.685.083 1.038.083H84c3.032 0 5.5 2.467 5.5 5.5v13.659h-9.938c-4.687 0-8.5 3.813-8.5 8.5v3.474c0 4.687 3.813 8.5 8.5 8.5H89.5V82.76zm4-17.159a.5.5 0 0 1-.5.5H79.562a5.506 5.506 0 0 1-5.5-5.5v-3.474c0-3.033 2.468-5.5 5.5-5.5H93a.5.5 0 0 1 .5.5v13.474z" fill="#000000" class="color000000 svgShape"></path><path d="M83.449 54.522a4.347 4.347 0 0 0-4.343 4.342c0 2.395 1.948 4.342 4.343 4.342s4.342-1.948 4.342-4.342a4.347 4.347 0 0 0-4.342-4.342zm0 5.685c-.74 0-1.343-.602-1.343-1.342a1.343 1.343 0 0 1 2.685 0c0 .739-.602 1.342-1.342 1.342z" fill="#000000" class="color000000 svgShape"></path></svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="disconnect" viewBox="0 0 24 24" id="logout"><path d="M20.9 11.6c-.1-.1-.1-.2-.2-.3l-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.3 1.3H13c-.6 0-1 .4-1 1s.4 1 1 1h4.6l-1.3 1.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3-3c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#17171a" class="color000000 svgShape"></path><path d="M15.5 18.1c-1.1.6-2.3.9-3.5.9-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C15.1 3.4 13.6 3 12 3c-5 0-9 4-9 9s4 9 9 9c1.6 0 3.1-.4 4.5-1.2.5-.3.6-.9.4-1.4-.3-.4-.9-.6-1.4-.3z" fill="#17171a" class="color000000 svgShape"></path></svg>
                        <span className="">Disconnect</span>
                        </div>
                      </span>
                    </div>
                  )}
                </div>
              </>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
        </Box>
      </Box>
    </div>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;

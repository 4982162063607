const service = {
  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  signin: "users/login",
  logout: "users/logout",
  getContent: "adminapi/getContent",
  changepswdlink: "users/changepswdlink",
  verifyForgotpasslink: "users/verifyForgotpasslink",
  resetpassword: "users/forgotpassword",
  getuserProfile: "users/userDetails",

  getUserDetails: "users/getUserDetails",
  mobilenumberupdate: "users/mobilenumberupdate",
  emailupdate: "users/emailupdate",
  getnotification: "users/getnotification",
  getSessionHisotry: "users/getSessionHisotry",
  // defaultBanks:"user/defaultBanks",
  generateAddress: "address/getUserAddress",
  verificationOtp: "users/verificationOtp",
  findDetails: "users/findDetails",
  getUserBalance: "users/getUserBalance",
  changePassword: "users/changePassword",
  tfaVerify: "users/tfa_login",
  getBalance: "/user/getAllBalances",
  resetPass: "/user/retetPassword",
  forgotepassword: "/user/forgotepassword",
  terms: "adminapi/terms",
  getCurrency: "users/getcurrency",
  getSiteDatas: "adminapi/get_sitedata",
  payment_request: "users/payment_request_v1",
  user_balance: "users/get_balance",
  deposit_history: "users/deposit_history",
  getfiatdeposit: "users/getfiatdeposit",

  fiatdeposit: "users/fiatdeposit",
  getbank: "users/getbank",


  
};

export default service;

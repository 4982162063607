import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import Header from "./Header";
import { Pagination } from '@mui/material';

import Moment from "moment";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
function Landing() {

  const [userBalance, setuserBalance] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const [depositHistory, setdepositHistory] = useState([]);

  useEffect(() => {
    getBalance();
    getdepositHistory();
    getProfile()
  }, [0]);

  async function getBalance() {
    try {

      const user_account = localStorage.getItem("user_token");
      if (user_account != null) {
        var data = {
          apiUrl: apiService.user_balance
        };
        var resp = await getMethod(data);
        if (resp.status) {
          setuserBalance(resp.balance);
        }
      }
      //}

    } catch (error) {

    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 5;

  useEffect(() => {
    getdepositHistory(currentPage);
  }, [currentPage]);

  async function getdepositHistory(pages) {
    try {
      var data = {
        apiUrl: apiService.getfiatdeposit,
        payload: { FilPerpage: recordsPerPage, FilPage: pages },

      };
      var resp = await postMethod(data);
      if (resp.status) {
        setdepositHistory(resp.data);
        setTotalPages(Math.ceil(resp.totalCount / recordsPerPage));
      } else {
        setdepositHistory([]);
        setTotalPages(0);
      }
    } catch (error) {

    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      // setSiteLoader(true);
      var resp = await getMethod(data);
      // setSiteLoader(false);


      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);
        setLastLogin(resp.lastLogin);

        const timestamp = resp.lastLogin.createdDate;
        const date = new Date(timestamp);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZoneCountry = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'}).formatToParts(date)
        //   .find(part => part.type === 'timeZoneName').value;
        setTimeZone(timeZone);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) { }
  };

  return (
    <div>
      <Header />
      <div className="Dashboard">
        <div className="container">
          <div className="dashin">
            <div className="head">
              <div className="row">
                <div className="col-md-4 mt-2">
                  <div className="dash-card">
                    <img
                      className="bg-image"
                      src={require("../images/card-bg.png")}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5>Balance</h5>
                        <h3 className="amount">
                          {" "}
                          <span>{userBalance? userBalance.toFixed(4) : 0 }</span> VTX
                        </h3>
                      </div>
                      <div>
                        <img
                          src={require("../images/light-logo.png")}
                          width="50px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mt-2">
                  <div className="dash-card new_card p-o">
                    <img
                      className="bg-image"
                      src={require("../images/card-bg.png")}
                    />

                    {/* <h5>Wallet Address</h5>
                    <div>
                      <h3 className="amount">
                        <>
                          1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa{" "}
                          <i class="fa-regular fa-copy"></i>
                        </>
                      </h3>
                    </div> */}

                    <div className="dash_new_card">
                      <div className="tokeno_tabl_frst">

                        <span className="fnt_24_ff">Email : <span className="hero_sub_main p-0"> {profileData.email}</span>                     </span>
                      </div>
                      {/* <span className="fnt_24_ff pr-4">
                        IP : <span className="hero_sub_main p-0">{lastLogin.ipAddress}</span>
                      </span> */}
                    </div>

                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="dash-card">
                    <h3 className="amount">
                      <>History</>
                    </h3>
                    <div class="table-responsive-lg">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" className="first">
                              SI.no
                            </th>
                            <th scope="col">Amount </th>
                            <th scope="col">Receive Token </th>

                            {/* <th scope="col">Type</th> */}
                            <th scope="col">Deposit Type</th>
                            <th scope="col">Status</th>


                            <th scope="col" className="last">
                              Date & Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <td scope="row">1</td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                            <td>DEX</td>
                            <td>1000 </td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                          </tr> */}
                          {depositHistory && depositHistory.length > 0 ? (
                            <>
                              {depositHistory.map((item, i) => (
                                <tr>
                                  <td scope="row">{i + 1}</td>
                                  <td>
                                    {item.amount} INR
                                  </td>
                                  <td>
                                    {item.receive} VTX
                                  </td>
                                  <td>
                                    {item.fiat_type == 0 ? "Manual" : "Direct"}
                                  </td>
                                  <td>
                                    {item.status == 0 ? "Pending" : item.status == 1 ? "Completed" : "Cancelled"}
                                  </td>
                                  <td>
                                    {Moment(item.createddate).format("lll")}

                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span>No Data Found</span>
                                </td>
                              </tr>
                            </>
                          )}

                          {/* <tr>
                            <td scope="row">2</td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                            <td>DEX</td>
                            <td>1000 </td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row">3</td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                            <td>DEX</td>
                            <td>1000 </td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                          </tr>

                          <tr>
                            <td scope="row">4</td>
                            <td>
                              {" "}
                              <div>
                                1HGVahf1g2vrjh...{" "}
                                <i class="fa-regular fa-copy"></i>
                              </div>{" "}
                            </td>
                            <td>DEX</td>
                            <td>1000 </td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">5</td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                            <td>DEX</td>
                            <td>1000 </td>
                            <td>
                              1HGVahf1g2vrjh...{" "}
                              <i class="fa-regular fa-copy"></i>
                            </td>
                          </tr> */}

                        </tbody>

                      </table>

                      {totalPages > 0 ? (
                        <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        shape="rounded"
                        className="table-pagination d-flex justify-content-center"
                      />
                      ) : ""}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

function Landing() {
    const [showStaticImage, setShowStaticImage] = useState(true);

    // useEffect(() => {
    //     const gifDuration = 4000; // Adjust the duration to match your GIF's duration

    //     const timer = setTimeout(() => {
    //         setShowStaticImage(true);
    //     }, gifDuration);

    //     return () => clearTimeout(timer);
    // }, []);

    const [OTP, setOTP] = useState("");
    const [buttonLoader, setbuttonLoader] = useState(false);
    // const [showError,setShowError] = useState(false);

    const navigate = useNavigate();

    const submit = async () => {
        try {
            if (OTP !== "") {
                console.log(OTP, "otp-=-=-");
                var obj = {
                    emailOtp: OTP,
                    email: localStorage.getItem("useremail"),
                };

                var data = {
                    apiUrl: apiService.emailotpverify,
                    payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                setbuttonLoader(false);
                if (resp.status == true) {
                    showsuccessToast(resp.Message);
                    navigate("/login");
                } else {
                    showerrorToast(resp.Message);
                }
            } else {
                showerrorToast("Enter OTP");
                // setShowError(true);
            }
        } catch (error) {
            setbuttonLoader(false);
        }
    };

    const resendOTP = async (payload) => {
        try {
            var obj = {
                email: localStorage.getItem("useremail"),
            };

            var data = {
                apiUrl: apiService.resendCode,
                payload: obj,
            };
            setbuttonLoader(true);

            var resp = await postMethod(data);
            setbuttonLoader(false);
            if (resp.status) {
                showsuccessToast(resp.Message);
            } else {
                showerrorToast(resp.Message);
            }
        } catch (error) {
            setbuttonLoader(false);
        }
    };

    const showsuccessToast = (message) => {
        toast.dismiss();
        toast.success(message);
    };

    const showerrorToast = (message) => {
        toast.dismiss();
        toast.error(message);
    };

    const [siteLoader, setSiteLoader] = useState(false);

    return (
        <div>
            <Header />
            <div className="register">
                <div className="container-fluid">
                    <div className="regbackground">
                        {!showStaticImage && (
                            <img
                                src={require("../images/register.gif")}
                                alt="Register Animation"
                                className="registerimage"
                            />
                        )}
                        {showStaticImage && (
                            <>
                                <img
                                    src={require("../images/Banner.png")}
                                    alt="Register Animation"
                                    className="registerimage"
                                />
                                <div className="container">
                                    <div className="form">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="login-form">
                                                    <h2>VOLTRIX Account Verification</h2>
                                                    <h6>OTP Sent your email</h6>

                                                    <div className="field">
                                                        <div className="label"> Enter Four Digit OTP</div>

                                                        <input
                                                            type="Number"
                                                            className="inputbox"
                                                            name="otp"
                                                            min={1000}
                                                            max={9999}
                                                            value={OTP}
                                                            onKeyDown={(evt) => {
                                                                if (["e", "E", "+", "-"].includes(evt.key)) {
                                                                    evt.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/\s/g, ""); // Remove white spaces
                                                                if (value.length <= 4) {
                                                                    setOTP(value);
                                                                }
                                                            }}
                                                            placeholder="Please enter your OTP"
                                                        />

                                                    </div>

                                                    <div className="reg-button mt-4">
                                                        {buttonLoader == false ? (

                                                            <button onClick={submit} >Verify</button>
                                                        ) : (

                                                            <button>Loading ...</button>
                                                        )}

                                                    </div>

                                                    <div>
                                                        <p className="links text-center" onClick={resendOTP}>
                                                            Resend <span>OTP ?</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;

import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import Header from "./Header";
import apiService from "../core/service/detail";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import { getMethod, postMethod } from "../core/service/common.api";
import ReactPaginate from "react-paginate";
import axios from "axios";
import env from "../core/service/envconfig";

function Landing() {
  const [usdtINRPrice, setUsdtINRPrice] = useState(0);
  const [formValue, setFormValue] = useState({
    amount: "",
    receiveToken: "",
    transactionId: "",
    transactionProof: "",
  });
  const [validationErr, setValidationErr] = useState({});
  const [proofLoading, setProofLoading] = useState(false);
  const [proofUrl, setProofUrl] = useState("");

  useEffect(() => {
    const getINRPrice = async () => {
      try {
        const response = await axios.get(
          `https://min-api.cryptocompare.com/data/pricemulti?fsyms=INR&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`
        );
        if (response.data && response.data.INR) {
          const inrToUsdt = response.data.INR.USDT;
          const vtxAmount = (1 / env.TTTusdtprice) * inrToUsdt;
          setUsdtINRPrice(vtxAmount.toFixed(4));
        }
      } catch (error) {
        console.error("Failed to fetch INR to USDT rate:", error);
        showerrorToast("Failed to fetch INR to USDT rate.");
      }
    };

    getINRPrice();
  }, []);

  useEffect(() => {
    const calculateReceiveToken = () => {
      if (formValue.amount) {
        const calculatedVtxAmount = (formValue.amount * usdtINRPrice).toFixed(
          4
        );
        setFormValue((prev) => ({
          ...prev,
          receiveToken: calculatedVtxAmount,
        }));
      }
    };

    calculateReceiveToken();
  }, [formValue.amount, usdtINRPrice]);

  const validate = (values) => {
    let errors = {};
    if (!values.amount) {
      errors.amount = "Amount is required!";
    }
    if (!values.receiveToken) {
      errors.receiveToken = "Receive Token is required!";
    }
    if (!values.transactionId) {
      errors.transactionId = "Transaction ID is required!";
    }
    if (!values.transactionProof) {
      errors.transactionProof = "Transaction proof is required!";
    }
    setValidationErr(errors);
    return errors;
  };

  const validate1 = (values) => {
    let errors = {};
    if (!values.amount) {
      errors.amount = "Amount is required!";
    }
    if (!values.receiveToken) {
      errors.receiveToken = "Receive Token is required!";
    }

    setValidationErr(errors);
    return errors;
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        showsuccessToast("Copied to clipboard successfully!");
      },
      (err) => {
        showerrorToast("Failed to copy to clipboard: ", err);
      }
    );
  };

  const [Bankdeatils, setBankdeatils] = useState({});

  useEffect(() => {
    getBank();
  }, []);

  const getBank = async () => {
    try {
      const data = {
        apiUrl: apiService.getbank,
      };

      const resp = await getMethod(data);

      if (resp.status === true) {
        setBankdeatils(resp.data);
      } else {
        setBankdeatils({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <div>
      <Header />
      <div className="Buyfiat">
        <div className="container">
          <div>
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="fiat-right">
                  <div className="forms">
                    <div className="head">
                      <span>Contact Details</span>

                      <div className="warning">
                        <p className="Note"> Note : </p>
                        <p className="Note text-white">
                          {" "}
                          Copy the following details into your bank's transfer
                          form
                        </p>
                      </div>
                    </div>
                    <div className="body">
                      <div className="input-groups">
                        <label>Email ID </label>
                        <div className="input1">
                          <input
                            className="input-class"
                            value={Bankdeatils.email}
                            disabled
                            placeholder="0.00"
                          />
                          <span>
                            <i
                              className="fa-regular fa-copy"
                              onClick={() => copyToClipboard(Bankdeatils.email)}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="input-groups">
                        <label>Mobile Number</label>
                        <div className="input1">
                          <input
                            className="input-class"
                            value={Bankdeatils.phone_no}
                            disabled
                            placeholder="0.00"
                          />
                          <span>
                            <i
                              className="fa-regular fa-copy"
                              onClick={() =>
                                copyToClipboard(Bankdeatils.phone_no)
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Header from "./Header";

function Landing() {
    const [showStaticImage, setShowStaticImage] = useState(true);

    // useEffect(() => {
    //     const gifDuration = 4000; // Adjust the duration to match your GIF's duration

    //     const timer = setTimeout(() => {
    //         setShowStaticImage(true);
    //     }, gifDuration);

    //     return () => clearTimeout(timer);
    // }, []);

    const initialFormValue = {
        email: "",
        password: "",
        confirmPassword: "",
        mobileNumber: ""
    };

    const [validationErr, setValidationErr] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [emailValidate, setEmailValidate, emailValidateRef] = useState(false);
    const [passwordValidate, setPasswordValidate, passwordValidateRef] = useState(false);
    const [
        confirmPasswordValidate,
        setConfirmPasswordValidate,
        confirmPasswordValidateRef,
    ] = useState(false);
    const [
        mobileNumberValidate,
        setMobileNumberValidate,
        mobileNumberValidateRef,
    ] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [terms, setTerms, termsRef] = useState(false);
    const [termsValidation, setTermsValidation] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [passHide, setPassHide] = useState(false);
    const [inputType, setInputType] = useState("password");
    const [passHideConf, setPassHideConf] = useState(false);
    const [inputTypeConf, setInputTypeConf] = useState("password");

    const { email, password, confirmPassword, mobileNumber } = formValue;
    const [siteLoader, setSiteLoader] = useState(false);

    const navigate = useNavigate();

    const createUid = uuidv4();
    const createdUuid = createUid.split("-")[0].toString();

    const handleChange = async (e) => {
        const { name, value } = e.target;
        let trimmedValue = value.replace(/\s/g, ""); // Remove all white spaces

        if (name === "mobileNumber") {
            // Allow only digits and limit to 10 characters
            trimmedValue = trimmedValue.replace(/\D/g, "").slice(0, 10);
        }

        // if (trimmedValue != "") {
            let formData = { ...formValue, ...{ [name]: trimmedValue } };
            setFormValue(formData);
            validate(formData);
        // }
       
    };

    const handleTerms = (event) => {
        setIsChecked(event.target.checked);
        setTerms(event.target.checked);
        setTermsValidation(!event.target.checked);
    };

    const validate = (values) => {
        let errors = {};
        const username = values.email.split("@")[0];

        if (!values.email) {
            errors.email = "Email is a required field!";
            setEmailValidate(true);
        } else if (
            !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address!";
            setEmailValidate(true);
        } else if (username.length < 4 || username.length > 30) {
            errors.email = "Email Username must be between 4-30 characters long";
            setEmailValidate(true);
        } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
            errors.email =
                " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
            setEmailValidate(true);
        } else if (!/[a-zA-Z]/.test(username)) {
            errors.email = "Email username must contain at least one letter (a-z)";
            setEmailValidate(true);
        } else if (values.email.length > 50) {
            errors.email = "Email address is too long!";
            setEmailValidate(true);
        } else if (!values.mobileNumber) {
            setEmailValidate(false);
            setMobileNumberValidate(true);
            errors.mobileNumber = "Phone number is required!";
        } else if (!values.password) {
            setMobileNumberValidate(false);
            setPasswordValidate(true);
            errors.password = "Password is required!";
        } else if (values.password.length < 5 || values.password.length > 25) {
            setPasswordValidate(true);
            errors.password = "Password should be between 5 and 25 characters!";
        } else if (!values.password.match(/[a-z]/g)) {
            setPasswordValidate(true);
            errors.password = "Please enter at least one lowercase character!";
        } else if (!values.password.match(/[A-Z]/g)) {
            setPasswordValidate(true);
            errors.password = "Please enter at least one uppercase character!";
        } else if (!values.password.match(/[0-9]/g)) {
            setPasswordValidate(true);
            errors.password = "Please enter at least one digit!";
        } else if (!values.password.match(/[!@#$%^&*]/g)) {
            setPasswordValidate(true);
            errors.password = "Please enter at least one special character!";
        } else if (!values.confirmPassword) {
            setPasswordValidate(false);
            errors.confirmPassword = "Confirm password is a required field";
            setConfirmPasswordValidate(true);
        } else if (
            values.password &&
            values.confirmPassword &&
            values.password !== values.confirmPassword
        ) {
            errors.confirmPassword = "Password and Confirm password do not match";
            setConfirmPasswordValidate(true);
        } else if (!terms) {
            setConfirmPasswordValidate(false);
            errors.terms = "Terms and Conditions are required";
            setTermsValidation(true);
        } else {
            errors.terms = "";
            setConfirmPasswordValidate(false);
            setPasswordValidate(false);
            setEmailValidate(false);
            setTermsValidation(false);
        }
        setValidationErr(errors);

        return errors;
    };

    const passwordHide = (data) => {
        if (data === "hide") {
            setPassHide(true);
            setInputType("text");
        } else {
            setPassHide(false);
            setInputType("password");
        }
    };

    const passwordHideConf = (data) => {
        if (data === "hide") {
            setPassHideConf(true);
            setInputTypeConf("text");
        } else {
            setPassHideConf(false);
            setInputTypeConf("password");
        }
    };

    const formSubmit = async () => {
        let errors = validate(formValue);

        formValue["UUID"] = createdUuid;

        if (
            emailValidateRef.current === false &&
            mobileNumberValidateRef.current === false &&
            passwordValidateRef.current === false &&
            confirmPasswordValidateRef.current === false &&
            terms === true
        ) {
            var data = {
                apiUrl: apiService.signup,
                payload: formValue,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setFormValue(initialFormValue);
            setIsChecked(false);
            localStorage.setItem("useremail", resp.email);
            setButtonLoader(false);
            if (resp.status) {
                toast.success(resp.Message);
                navigate("/verify-email");
            } else {
                toast.error(resp.Message);
            }
        }
    };

    return (
        <div>
            <Header />
            <div className="register">
                <div className="container-fluid">
                    <div className="regbackground">
                        {!showStaticImage && (
                            <img
                                src={require("../images/register.gif")}
                                alt="Register Animation"
                                className="registerimage"
                            />
                        )}
                        {showStaticImage && (
                            <>
                                <img
                                    src={require("../images/Banner.png")}
                                    alt="Register Animation"
                                    className="registerimage"
                                />
                                <div className="container">
                                    <div className="form">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="login-form">
                                                    <h2>Create VOLTRIX Account</h2>
                                                    <h6>Register with your email</h6>

                                                    <div className="field">
                                                        <div className="label">Email</div>

                                                        <input
                                                            type="text"
                                                            className="inputbox"
                                                            name="email"
                                                            value={formValue.email}
                                                            onChange={handleChange}
                                                            placeholder="Please enter your email address"
                                                        />
                                                        {validationErr && validationErr.email && (
                                                            <p className="errorcss">{validationErr.email}</p>
                                                        )}
                                                    </div>

                                                    <div className="field">
                                                        <div className="label">Phone Number</div>

                                                        <input
                                                            type="number"
                                                            className="inputbox"
                                                            name="mobileNumber"
                                                            value={mobileNumber}
                                                            onChange={handleChange}
                                                            placeholder="Please enter your phone number"
                                                            maxLength={10}
                                                            pattern="\d*"
                                                        />
                                                        {validationErr && validationErr.mobileNumber && (
                                                            <p className="errorcss">{validationErr.mobileNumber}</p>
                                                        )}
                                                    </div>

                                                    <div className="field">
                                                        <div className="label">Password</div>

                                                        <div className="pass">
                                                            <input
                                                                type={inputType}
                                                                value={password}
                                                                minLength={6}
                                                                name="password"
                                                                maxLength={15}
                                                                onChange={handleChange}
                                                                className="inputbox"
                                                                placeholder="Please create a password"
                                                            />
                                                            {passHide ? (
                                                                <i
                                                                    className="fa-regular fa-eye reg_eye"
                                                                    onClick={() => passwordHide("show")}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa-regular fa-eye-slash reg_eye"
                                                                    onClick={() => passwordHide("hide")}
                                                                ></i>
                                                            )}
                                                        </div>
                                                        {validationErr && validationErr.password && (
                                                            <p className="errorcss">{validationErr.password}</p>
                                                        )}
                                                    </div>

                                                    <div className="field">
                                                        <div className="label">Confirm Password</div>

                                                        <div className="pass">
                                                            <input
                                                                type={inputTypeConf}
                                                                value={confirmPassword}
                                                                minLength={6}
                                                                maxLength={15}
                                                                name="confirmPassword"
                                                                onChange={handleChange}
                                                                className="inputbox"
                                                                placeholder="Please confirm the password"
                                                            />
                                                            {passHideConf ? (
                                                                <i
                                                                    className="fa-regular fa-eye reg_eye"
                                                                    onClick={() => passwordHideConf("show")}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa-regular fa-eye-slash reg_eye"
                                                                    onClick={() => passwordHideConf("hide")}
                                                                ></i>
                                                            )}
                                                        </div>
                                                        {validationErr && validationErr.confirmPassword && (
                                                            <p className="errorcss">{validationErr.confirmPassword}</p>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <p className="links">
                                                            <input
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onChange={handleTerms}
                                                                id="customCheck"
                                                                className="checkbox-round"
                                                            />
                                                            <p className="text-center">I have read and agree to the
                                                            <span> Terms and Conditions</span></p>
                                                        </p>
                                                        {termsValidation && (
                                                            <p className="errorcss mb-3">
                                                                Terms and Conditions are required
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="reg-button">
                                                        {!buttonLoader ? (
                                                            <button onClick={formSubmit}>Register</button>
                                                        ) : (
                                                            <button>Loading ....</button>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <p className="links text-center">
                                                            Already registered? 
                                                            <Link to="/login">
                                                                {" "}
                                                                <span> Log In</span>{" "}
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;

import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import Header from "./Header";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount,

} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";
import SwapBNBforTokenABI from "./SwapBNBforTokenABI.json";
import USDTbalanceABI from "./USDTbalanceABI.json";
import BNBforTokenABI from "./BNBforTokenABI.json";
import axios from "axios";
import env from "../core/service/envconfig";
import { toast } from "react-toastify";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import apiService from "../core/service/detail";
import { getMethod,postMethod } from "../core/service/common.api";

function Landing() {

  const navigate = useNavigate();

  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x38,
    name: "Binance Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: "https://bsc-dataseed1.binance.org/",

    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
    coinbasePreference: "eoaOnly",
    auth: {
      email: false, // default to true
      // socials: ['google', 'x', 'github', 'discord', 'apple'],
      showWallets: true, // default to true
      walletFeatures: true, // default to true
    },
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    includeWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    ],
  });
  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const [allCurrency, setAllCurrency, allCurrencyref] = useState([]);
  const [allCurrencyFiat, setAllCurrencyfiat] = useState([]);
  const [selectedCurrency, setSelectedCurrency,selectedCurrencyref] = useState("BNB");
  const [selectedCurrencyFiat, setSelectedCurrencyFiat] = useState("INR");

  const [bnbBalance, setBnbBalance] = useState(0);
  const [tokenBalance, setTokenBalance, tokenBalanceref] = useState(0);
  const [USDTBalance, setUSDTBalance] = useState(0);

  const [bnbAmount, setBnbAmount] = useState("");
  const [tttAmount, setTttAmount] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [BNBprice, setBNBprice] = useState(0);

  const [usdtINRPrice, setusdtINRPrice] = useState(0);
  
  const [INRprice, setINRprice] = useState(0);

  const [inrAmount, setinrAmount] = useState("");
  const [vtxAmount, setvtxAmount] = useState("");

  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [swapContract, setSwapContract] = useState(null);

  const [userAccount, setUserAccount] = useState(null);

  const [userToken, setuserToken] = useState(null);
  const [userDetails,setuserDetails] = useState(null);

  const formatter_interest = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setAllCurrency(currArrayCrypto);

    console.log(allCurrencyref.current, 
      "allCurrency" , allCurrency
     )


    setSelectedCurrency(currArrayCrypto[0].value);

    console.log(selectedCurrencyref.current, 
      "allCurrency" , selectedCurrency
     )

    const currArrayFiat = [
      {
        value: "INR",
        key: "INR",
        text: "INR",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/dvlfcoxxp/image/upload/v1721407155/flag-4_bhlflc.webp",
        },
      }
    ];
    setAllCurrencyfiat(currArrayFiat);
    setSelectedCurrencyFiat(currArrayFiat[0].value);

    FetchwalletUSDTbalance();

    var token = localStorage.getItem("user_token");
    if (token != null) {
      setuserToken(token);
      getUser();
     
    }
    // console.log("userToken",userToken)
    // console.log("userAccount",userAccount)

  }, [0]);

  async function getUser()
  {
    try {

      var data = {
        apiUrl: apiService.getuserProfile
      };
      var resp = await getMethod(data);
      if(resp.status)
      {
        setuserDetails(resp.data);
      }
      
    } catch (error) {
      
    }
    

  }

  async function FetchwalletUSDTbalance() {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#ffc630",
      svgSize: "100px",
      backgroundColor: "#17171a90",
    });
    if (userAccount) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const contractInstance = new Contract(
        env.usdtAddres,
        USDTbalanceABI,
        bnbProvider
      );
      console.log(contractInstance, "contractInstance", userAccount);
      const usdtbalance = await contractInstance.balanceOf(userAccount);

      console.log(usdtbalance, "usdtbalance");

      const web3Instance = new Web3(window.ethereum);

      const ethBalance = web3Instance.utils.fromWei(usdtbalance, "ether");

      setUSDTBalance(ethBalance);
      console.log(ethBalance);
    }
    Loading.remove();
  }

  async function getBalance1() {
    try {
      const account = localStorage.getItem("userAddress");
      const provider = new BrowserProvider(walletProvider);

      const balance = await provider.getBalance(account);
      const ethBalance = parseInt(balance) / 1000000000000000000;
      setBnbBalance(ethBalance);
      localStorage.setItem("Balance", ethBalance);
    } catch (error) {
      // console.log("---","balance fet error",error)
    }
  }
  const handleWalletConnect = async () => {
    await open();

    // console.log("hihihihihiihihiihihihihihiihihihihihihihihihihihihi");
  };


  const getbalanceaddress = async () => {


    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#ffc630",
        svgSize: "100px",
        backgroundColor: "#17171a90",
      });
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setUserAccount(address);
        const balance = await provider.getBalance(address);
        localStorage.setItem("userAddress", address);
        const ethBalance = parseInt(balance) / 1000000000000000000;
        setBnbBalance(ethBalance);
        localStorage.setItem("Balance", ethBalance);

        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const Tokencontarct = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          bnbProvider
        );
        const tokenBalance = await Tokencontarct.balanceOf(address);

        localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);

        FetchwalletUSDTbalance();
        initializeWeb3();
        //  Loading.remove();
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }

  };

  const { chainId, isConnected } = useWeb3ModalAccount();


  if (isConnected) {
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }

  // const handleWalletConnect = async () => {
  //   await open();
  //   try {
  //     Loading.hourglass("Connect with Metamask network.... ", {
  //       messageColor: "red",
  //       svgColor: "#ffc630",
  //       svgSize: "100px",
  //       backgroundColor: "#17171a90",
  //     });
  //     try {
  //       const provider = new BrowserProvider(walletProvider);
  //       const signer = await provider.getSigner();
  //       const address = await signer.getAddress();
  //       setUserAccount(address);
  //       const balance = await provider.getBalance(address);
  //       localStorage.setItem("userAddress", address);
  //       const ethBalance = parseInt(balance) / 1000000000000000000;
  //       setBnbBalance(ethBalance);
  //       localStorage.setItem("Balance", ethBalance);

  //       const bnbProvider = new JsonRpcProvider(env.rpc_url);

  //       const Tokencontarct = new Contract(
  //         env.tokenAddres,
  //         BNBforTokenABI,
  //         bnbProvider
  //       );
  //       const tokenBalance = await Tokencontarct.balanceOf(address);

  //       localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);

  //       FetchwalletUSDTbalance();
  //       initializeWeb3();
  //       //  Loading.remove();
  //     } catch (error) {
  //       console.error(error);
  //        Loading.remove();
  //     }
  //   } catch (err) {
  //     //  Loading.remove();
  //   }
  // };

  useEffect(() => {
    initializeWeb3();
    const intervalId = setInterval(initializeWeb3, 2000);
    return () => clearInterval(intervalId);
  }, [0]);

  const initializeWeb3 = async () => {
    const userAddress = localStorage.getItem("userAddress");


    if (userAddress != null) {
      const Bnbbalance = localStorage.getItem("Balance");
      const TTTbalance = localStorage.getItem("TTTBalance");

      setUserAccount(userAddress);
      setTokenBalance(TTTbalance);
      setBnbBalance(Bnbbalance);

      getBalance1();

      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      const contractInstance = new web3Instance.eth.Contract(
        SwapBNBforTokenABI,
        env.BuyAddress
      );
      setSwapContract(contractInstance);

      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      if (walletProvider) {
        try {
          const provider = new BrowserProvider(walletProvider);
          console.log(provider, "provider");
          const signer = await provider.getSigner();

          const contract1 = new Contract(
            env.tokenAddres,
            BNBforTokenABI,
            signer
          );

          setContract(contract1);

          console.log(contract1, "contract1");

          const tokenBalance = await contract1.balanceOf(userAddress);
          setTokenBalance(Number(tokenBalance) / 1000000000000000000);

          console.log(tokenBalance, "userAddress");


          localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000000000000000);
        } catch (error) {
          console.error(
            "Error initializing provider or fetching token balance:",
            error
          );
        }
      } else {
        console.error("walletProvider is null or undefined");
      }
    } else {
      setUserAccount("");
      setTokenBalance(0);
      setBnbBalance(0);
    }
  };

  useEffect(() => {
    const getUsdtPrice = async () => {
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#ffc630",
        svgSize: "100px",
        backgroundColor: "#17171a90",
      });
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=BNB&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      const response = await axios.get(apiUrl);
      if (response.data && response.data.BNB) {
        const value = response.data.BNB.USDT;
        let unitCalculation;

        if (selectedCurrency === "BNB") {
          unitCalculation = (value * 1) / +env.TTTusdtprice;
          setBNBprice(value.toFixed(4));
          setUsdtPrice(unitCalculation.toFixed(4));
        } else {
          unitCalculation = 1 / +env.TTTusdtprice;
          setBNBprice(1);
          setUsdtPrice(unitCalculation.toFixed(4));
        }
      }
      Loading.remove();
    };

    getUsdtPrice();
  }, [selectedCurrency]);

  useEffect(() => {
    const calculatedTttAmount = bnbAmount * usdtPrice;
    setTttAmount(calculatedTttAmount);
  }, [bnbAmount, usdtPrice]);

  useEffect(() => {
    const calculatedvtxAmount = inrAmount * usdtINRPrice;
    setvtxAmount(calculatedvtxAmount);
  }, [inrAmount, usdtINRPrice]);


  useEffect(() => {
    const getINRPrice = async () => {
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#ffc630",
        svgSize: "100px",
        backgroundColor: "#17171a90",
      });
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=INR&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      const response = await axios.get(apiUrl);
      if (response.data && response.data.INR) {
        const value = response.data.INR.USDT;
        let unitCalculation;
        unitCalculation = (value * 1) / +env.TTTusdtprice;
        setINRprice(value.toFixed(4));
        setusdtINRPrice(unitCalculation.toFixed(4));
      }
      Loading.remove();
    };

    getINRPrice();
  }, [selectedCurrency]);



  const connectwalletBuy = async () => {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#ffc630",
      svgSize: "100px",
      backgroundColor: "#17171a90",
    });

    try {
      let Buy;

      if (tttAmount >= 1) {
        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        console.log(walletProvider, "walletProvider")

        const provider = new BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        console.log(signer, "signers");

        const tokenContract_usdt = new Contract(
          env.usdtAddres,
          USDTbalanceABI,
          signer
        );

        const swapContract1 = new Contract(
          env.BuyAddress,
          SwapBNBforTokenABI,
          signer
        );

        const contract1 = new Contract(env.tokenAddres, BNBforTokenABI, signer);

        if (selectedCurrency == "USDT") {
          if (USDTBalance < 0 || bnbAmount > USDTBalance) {
            showerrorToast("Insufficient USDT Balance");
            Loading.remove();
          } else {
            console.log("call usdt");

            const weiValue = web3.utils.toWei(bnbAmount, 'ether');

            console.log(weiValue, "weiValue");

            const receiveamount = web3.utils.toWei(tttAmount, 'ether');
            console.log(receiveamount, "receiveamount");

            var fees = (bnbAmount * 1) / 100;
            console.log(fees, "fees");

            var remaining_amount = bnbAmount - fees;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            console.log(fees, "fees", tokenContract_usdt);

            const gasPrice = (await provider.getFeeData()).gasPrice;

            try {
              var Approved = await tokenContract_usdt.approve(
                env.BuyAddress,
                weiValue.toString(),
                // {
                //   gasLimit: 31000, // Use estimated gas
                //   gasPrice: gasPrice, // Use current gas price
                // }
              )
              await Approved.wait();
              console.log("Approved",Approved);
              if (Approved) {
                Buy = await swapContract1.buy_usdt(
                  userAccount,
                  receiveamount,
                  weiValue.toString(),
                  env.usdtAddres,
                  env.tokenAddres,
                  // {
                  //   gasLimit: 1000000, // Use estimated gas
                  //   gasPrice: gasPrice, // Use current gas price
                  // }
                );
                await Buy.wait();
                if (Buy) {
                  showsuccessToast("Token Purcashed Sucessfully");
                  setBnbAmount("");
                  Loading.remove();
                  initializeWeb3();
                }

              }
            } catch (error) {
              console.log("error", error);
              Loading.remove();

              showerrorToast("Transaction failed");
            }
          }
        } else {
          if (bnbBalance < 0 || bnbAmount > bnbBalance) {
            showerrorToast("Insufficient BNB Balance");
            Loading.remove();
          } else {
            const weiValue = web3.utils.toWei(bnbAmount, 'ether');

            // bnbAmount * 1000000000000000000;

            console.log(weiValue, "weiValue");

            const totals = tttAmount.toFixed(4);
            console.log(bnbAmount, totals);


            const receiveamount = web3.utils.toWei(tttAmount, 'ether')
            //  totals * 1000000000000000000;

            console.log(receiveamount, "receiveamount");

            var fees = (bnbAmount * 1) / 100;
            var remaining_amount = bnbAmount - fees;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            console.log(remaining_amount, "remaining_amount", fees);

            const gasPrice = (await provider.getFeeData()).gasPrice;

            // const gasPrice = await web3.eth.getGasPrice();

            try {
              console.log(Number(gasPrice), "uuhuhuhuh");
              var transaction = await swapContract1.buyToken(
                userAccount,
                receiveamount,
                weiValue.toString(),
                env.tokenAddres,

                {
                  value: weiValue.toString(),
                  //   gasLimit: 31000, // Use estimated gas
                  //   gasPrice: gasPrice, // Use current gas price
                }
              );

              await transaction.wait();

              console.log("Transaction successful:", transaction);

              if (transaction) {
                showsuccessToast("Token Purcashed Sucessfully");
                setBnbAmount("");
                Loading.remove();
              }
              // const updatedTokenBalance = await contract1.methods
              // .balanceOf(userAccount)
              // .call();

              console.log(contract1, "contract");

              initializeWeb3();


              // const Tokencontarct = new Contract(
              //   env.tokenAddres,
              //   BNBforTokenABI,
              //   bnbProvider
              // );
              // const tokenBalance = await Tokencontarct.balanceOf(userAccount);

              // console.log(tokenBalance, "tokenBalance");

              // localStorage.setItem(
              //   "TTTBalance",
              //   Number(tokenBalance) / 1000000000000000000
              // );

              // setTokenBalance(Number(tokenBalance) / 1000000000000000000);
              // FetchwalletUSDTbalance();
            } catch (transactionError) {
              console.error("Error executing transaction:", transactionError);
              showerrorToast("Transaction failed");
              Loading.remove();
            }
          }
        }
      } else {
        Loading.remove();
        showerrorToast("Buy Minimum 1 VTX");
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
    }
  };

  const selectToken = (value) => {
    FetchwalletUSDTbalance();

    setSelectedCurrency(value);
  };

    const selectTokenFiat = (value) => {
    FetchwalletUSDTbalance();

    setSelectedCurrencyFiat(value);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const login_nav = async () => {
    navigate("/login")
  };

  const handleBuy = async () => {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#ffc630",
      svgSize: "100px",
      backgroundColor: "#17171a90",
    });

    try {
      if (vtxAmount >= 1)
      {
          var payload = {
            purpose: 'Testing VTX',
            inrAmount: inrAmount,
            mobileNumber: userDetails.mobileNumber,
            username: userDetails.username,
            email: userDetails.email,
          }
          console.log("payload",payload);
          var data = {
            apiUrl: apiService.payment_request,
            payload: payload,
          };
          var resp = await postMethod(data);
          if(resp.status)
          {
            window.location.href = resp.payment_link;
          }

      } else {
        Loading.remove();

        showerrorToast("Buy Minimum 1 VTX");
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
    }
  };


  return (
    <div>
      <Header />
      <div className="Buy">
        <div className="container">
          <div className="dashin">
            <div className="head">
              <div className="row justify-content-center">
                <div className="col-md-6">
                {/* {(userToken === null || userToken === "" || userToken === undefined) && (userAccount === null || userAccount === "" || userAccount === undefined) ? (
                  <div className="buy-card">
                    <ul class="nav nav-tabs">
                      <li class="active_tab">
                        <a data-toggle="tab" href="#home" className="active">
                          <button className="order-btn-place">
                            <span className=""> Fiat</span>
                          </button>
                        </a>
                      </li>
                      <li class="active_tab">
                        <a data-toggle="tab" href="#menu2">
                          <button className="order-btn-place">
                            <span className=""> Crypto </span>
                          </button>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div id="home" class="tab-pane fade in active show">
                        <div className="dash-tab">
                          <div className="dab">
                            <h6 className="m-0">Pay</h6>
                            <h6 className="m-0">
                            </h6>
                          </div>
                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4 className="over_x_scroll">
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  value={inrAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    setinrAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                                <Dropdown
                                  fluid
                                  value={selectedCurrencyFiat}
                                  selection
                                  options={allCurrencyFiat}
                                  onChange={(e, { value }) =>
                                    selectTokenFiat(value)
                                  }
                                />
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6 className="m-0">Receive</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {formatter_interest.format(
                                  tokenBalanceref.current
                                )}{" "}
                                VTX{" "}
                              </span>
                            </h6>
                          </div>

                          <div className="betcen height55">
                            <div className="w-50">
                              <h4 className="over_x_scroll">{parseFloat(vtxAmount).toFixed(4)}</h4>
                            </div>
                            <div>
                              <h4 className="mr-3">
                                <img
                                  src={
                                    "https://res.cloudinary.com/dh3iko4f6/image/upload/v1723278142/Voltrix_Crypt_logo_Without_Text_pu4wnc.png"
                                  }
                                  width="30px"
                                  className="mr-3 vtximage"
                                />{" "}
                                VTX
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6>
                              <span>1{selectedCurrencyFiat}</span>
                            </h6>
                            <h6>
                              {" "}
                              <span>
                                {" "}
                                {usdtINRPrice} VTX (₹ {INRprice})
                              </span>{" "}
                            </h6>
                          </div>
                          <div className="reg-button">
                            {userToken ? (

                              <button onClick={handleBuy}>Buy</button>) : (
                              <button onClick={login_nav}> Login </button>

                            )}
                          </div>
                        </div>
                      </div>
                      <div id="menu2" class="tab-pane fade">
                        
                         <div className="dash-tab">
                          <div className="dab">
                            <h6 className="m-0">Pay</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {selectedCurrencyref.current === "BNB" ? (
                                  <>
                                    {formatter_interest.format(bnbBalance)} BNB
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {formatter_interest.format(
                                      USDTBalance
                                    )}{" "}
                                    USDT
                                  </>
                                )}{" "}
                              </span>
                            </h6>
                          </div>
                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4 className="over_x_scroll">
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  value={bnbAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    setBnbAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                                <Dropdown
                                  fluid
                                  value={selectedCurrencyref.current}
                                  selection
                                  options={allCurrency}
                                  onChange={(e, { value }) =>
                                    selectToken(value)
                                  }
                                />
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6 className="m-0">Receive</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {formatter_interest.format(
                                  tokenBalanceref.current
                                )}{" "}
                                VTX{" "}
                              </span>
                            </h6>
                          </div>

                          <div className="betcen height55">
                            <div className="text-end w-50">
                              <h4 className="over_x_scroll">{tttAmount.toFixed(4)}</h4>
                            </div>
                            <div>
                              <h4 className="mr-3">
                                <img
                                  src={
                                    "https://res.cloudinary.com/dh3iko4f6/image/upload/v1723278142/Voltrix_Crypt_logo_Without_Text_pu4wnc.png"
                                  }
                                  width="30px"
                                  className="mr-3 vtximage"
                                />{" "}
                                VTX
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6>
                              <span>1{selectedCurrency}</span>
                            </h6>
                            <h6>
                              {" "}
                              <span>
                                {" "}
                                {usdtPrice} VTX ($ {BNBprice})
                              </span>{" "}
                            </h6>
                          </div>
                          <div className="reg-button">
                            {userAccount ? (

                              <button onClick={connectwalletBuy}>Buy</button>) : (
                              <button onClick={handleWalletConnect}> Connect Wallet </button>

                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                    </div>
                    ) : ("")} */}
                    {/* {userToken != null && userToken != "" && userToken != undefined ? (
                      <>
                       <div className="buy-card">
                       <div class="tab-content">
                      <div id="home" class="tab-pane fade in active show">
                        <div className="dash-tab">
                          <div className="dab">
                            <h6 className="m-0">Pay</h6>
                            <h6 className="m-0">
                            </h6>
                          </div>
                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4>
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  value={inrAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    setinrAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                                <Dropdown
                                  fluid
                                  value={selectedCurrencyFiat}
                                  selection
                                  options={allCurrencyFiat}
                                  onChange={(e, { value }) =>
                                    selectTokenFiat(value)
                                  }
                                />
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6 className="m-0">Receive</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {formatter_interest.format(
                                  tokenBalanceref.current
                                )}{" "}
                                VTX{" "}
                              </span>
                            </h6>
                          </div>

                          <div className="betcen height55">
                            <div className="text-end w-50">
                              <h4 className="over_x_scroll">{parseFloat(vtxAmount).toFixed(4)}</h4>
                            </div>
                            <div>
                              <h4 className="mr-3">
                                <img
                                  src={
                                    "https://res.cloudinary.com/dh3iko4f6/image/upload/v1723278142/Voltrix_Crypt_logo_Without_Text_pu4wnc.png"
                                  }
                                  width="30px"
                                  className="mr-3 vtximage"
                                />{" "}
                                VTX
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6>
                              <span>1{selectedCurrencyFiat}</span>
                            </h6>
                            <h6>
                              {" "}
                              <span>
                                {" "}
                                {usdtINRPrice} VTX (₹ {INRprice})
                              </span>{" "}
                            </h6>
                          </div>
                          <div className="reg-button">
                            {userToken ? (

                              <button onClick={handleBuy}>Buy</button>) : (
                              <button onClick={login_nav}> Login </button>

                            )}
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      </>
                      ) : ("")} */}
                      
                    {/* {userAccount != null && userAccount != "" && userAccount != undefined ? ( */}
                      <>
                       <div className="buy-card">
                       <div class="tab-content">
                      <div id="menu2" class="tab-pane fade in active show">
                        {/* <div className="dash-tab">
                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4>
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  //   value={bnbAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    // setBnbAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                                <Dropdown
                                  fluid
                                  value={selectedCurrency}
                                  selection
                                  options={allCurrency}
                                //   onChange={(e, { value }) => selectToken(value)}
                                />
                              </h4>
                            </div>
                          </div>

                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4>
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  //   value={bnbAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    // setBnbAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                                <Dropdown
                                  fluid
                                  value={selectedCurrency}
                                  selection
                                  options={allCurrency}
                                //   onChange={(e, { value }) => selectToken(value)}
                                />
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6>Balance</h6>
                            <h6>0.00 VTH</h6>
                          </div>

                          <div className="dab">
                            <h6>1 VTH </h6>
                            <h6>Rupees 0.00</h6>
                          </div>
                          <div className="reg-button">

                            {userAccount != null ? (

                              <button onClick={connectwalletBuy}>Buy</button>) : (
                              <button onClick={handleWalletConnect}> Connect Wallet </button>

                            )}
                          </div>
                        </div> */}
                         <div className="dash-tab">
                          <div className="dab">
                            <h6 className="m-0">Pay</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {selectedCurrency === "BNB" ? (
                                  <>
                                    {formatter_interest.format(bnbBalance)} BNB
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {formatter_interest.format(
                                      USDTBalance
                                    )}{" "}
                                    USDT
                                  </>
                                )}{" "}
                              </span>
                            </h6>
                          </div>
                          <div className="betcen">
                            <div className="text-end w-50">
                              <h4>
                                <input
                                  type="number"
                                  onKeyDown={(evt) =>
                                    [
                                      "ArrowUp",
                                      "ArrowDown",
                                      "e",
                                      "E",
                                      "+",
                                      "-",
                                    ].includes(evt.key) && evt.preventDefault()
                                  }
                                  value={bnbAmount}
                                  placeholder="0.00"
                                  className="w-100"
                                  onChange={(e) => {
                                    setBnbAmount(e.target.value);
                                  }}
                                />
                              </h4>
                            </div>
                            <div>
                              <h4>
                              <Dropdown
      fluid
      value={selectedCurrency}
      selection
      options={allCurrencyref.current}
      onChange={(e, { value }) => selectToken(value)}
    />
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6 className="m-0">Receive</h6>
                            <h6 className="m-0">
                              <span>
                                Balance{" "}
                                {formatter_interest.format(
                                  tokenBalanceref.current
                                )}{" "}
                                VTX{" "}
                              </span>
                            </h6>
                          </div>

                          <div className="betcen height55">
                            <div className="text-end over_x_scroll w-50">
                              <h4 className="over_x_scroll">{tttAmount.toFixed(4)}</h4>
                            </div>
                            <div>
                              <h4 className="mr-3">
                                <img
                                  src={
                                    "https://res.cloudinary.com/dh3iko4f6/image/upload/v1723278142/Voltrix_Crypt_logo_Without_Text_pu4wnc.png"
                                  }
                                  width="30px"
                                  className="mr-3 vtximage"
                                />{" "}
                                VTX
                              </h4>
                            </div>
                          </div>

                          <div className="dab">
                            <h6>
                              <span>1 {" "}{selectedCurrency}</span>
                            </h6>
                            <h6>
                              {" "}
                              <span>
                                {" "}
                                {usdtPrice} VTX ($ {BNBprice})
                              </span>{" "}
                            </h6>
                          </div>
                          <div className="reg-button">
                            {userAccount ? (

                              <button onClick={connectwalletBuy}>Buy</button>) : (
                              <button onClick={handleWalletConnect}> Connect Wallet </button>

                            )}
                          </div>
                        </div>

                      </div>
                      </div>
                      </div>
                      </>
                      {/* // ) : ("")} */}
                </div>
              </div>
            </div>
            <div className="body"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import Header from "./Header";
import Slider from 'react-slick';
import { Link } from "react-router-dom";

function Landing() {

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Target date and time for the pre-sale event in UTC
    const targetDateUTC = new Date("2024-07-31T00:00:00Z");// 00:00:00 EST is 04:00:00 UTC

    const updateTimer = () => {
      const nowUTC = new Date();
      const difference = targetDateUTC - nowUTC;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60  * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        // Countdown complete, set all values to 0
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timerId = setInterval(updateTimer, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, []);


  const rectsecondRef = useRef(null);

  useEffect(() => {
    const length = rectsecondRef.current.getTotalLength();
    const dashLength = 60; // Length of the dash
    const gapLength = length - dashLength; // Length of the gap

    gsap.set(rectsecondRef.current, {
      strokeDasharray: `${dashLength} ${gapLength}`,
      strokeDashoffset: 0,
    });

    gsap.to(rectsecondRef.current, {
      strokeDashoffset: -length,
      duration: 8, // Adjust the duration for slower speed
      repeat: -1, // Repeat infinitely
      ease: 'linear', // Linear easing for smooth continuous animation
    });

  }, []);
  const rectsecondfrstRef = useRef(null);

  useEffect(() => {
    const length = rectsecondfrstRef.current.getTotalLength();
    const dashLength = 60; // Length of the dash
    const gapLength = length - dashLength; // Length of the gap

    gsap.set(rectsecondfrstRef.current, {
      strokeDasharray: `${dashLength} ${gapLength}`,
      strokeDashoffset: 0,
    });

    gsap.to(rectsecondfrstRef.current, {
      strokeDashoffset: -length,
      duration: 8, // Adjust the duration for slower speed
      repeat: -1, // Repeat infinitely
      ease: 'linear', // Linear easing for smooth continuous animation
    });

  }, []);

  const rectunlockRef = useRef(null);

  useEffect(() => {
    const length = rectunlockRef.current.getTotalLength();
    const dashLength = 60; // Length of the dash
    const gapLength = length - dashLength; // Length of the gap

    gsap.set(rectunlockRef.current, {
      strokeDasharray: `${dashLength} ${gapLength}`,
      strokeDashoffset: 0,
    });

    gsap.to(rectunlockRef.current, {
      strokeDashoffset: -length,
      duration: 8, // Adjust the duration for slower speed
      repeat: -1, // Repeat infinitely
      ease: 'linear', // Linear easing for smooth continuous animation
    });

  }, []);


  const lineRef = useRef(null);

  useEffect(() => {
    const length = lineRef.current.getTotalLength();
    const dashLength = 80; // Length of the moving segment
    const gapLength = length - dashLength; // Gap to ensure a single dash

    gsap.set(lineRef.current, {
      strokeDasharray: `${dashLength} ${gapLength}`,
      strokeDashoffset: 0,
    });

    gsap.to(lineRef.current, {
      strokeDashoffset: -length,
      duration: 8, // Adjust the duration for slower speed
      repeat: -1, // Repeat infinitely
      ease: "linear", // Linear easing for smooth continuous animation
    });

  }, []);


  const [hoveredCells, setHoveredCells] = useState([]);

  const hoverSound = new Audio(require('../images/videoplayback.mp3')); // Replace with your sound file
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  


  const handlebuttonsAudio = () => {
    var audio = document.getElementsByTagName("audio")[0];
    audio.play();
  }
 

  const handleMouseEnter = (targets) => {
    setHoveredCells(targets);
    var audio = document.getElementsByTagName("audio")[0];
    audio.play();
  };

  const handleMouseLeave = () => {
    setHoveredCells([]);
  };

  const imageClass = (target) => {
    let className = 'tokeno_left_frst';
    if (hoveredCells.includes(target)) {
      className += ' opacity-05';
    } else if (hoveredCells.length > 0) {
      className += ' scale-1';
    }
    return className;
  };
 
 
  const buyChickenRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const maxOffset = 2550 - window.innerWidth;

  useEffect(() => {
    const handleScroll = () => {
      if (buyChickenRef.current) {
        const sectionTop = buyChickenRef.current.offsetTop;
        const sectionHeight = buyChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        // console.log(pageOffset, sectionTop, sectionTop + sectionHeight);

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight &&
          !isLocked
        ) {
          setIsLocked(true);
          document.body.classList.add('no-scroll');
        } else if (pageOffset < sectionTop || pageOffset > sectionTop + sectionHeight) {
          setIsLocked(false);
          document.body.classList.remove('no-scroll');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLocked]);

  useEffect(() => {
    const handleHorizontalScroll = (e) => {
      if (isLocked) {
        e.preventDefault();
        const newOffset = offset + e.deltaY * 0.8;

        console.log(newOffset, maxOffset, "ihihihiiinik");

        if (newOffset >= 0 && newOffset <= maxOffset) {
          setOffset(newOffset);
          buyChickenRef.current.scrollLeft = newOffset;
        } else if (newOffset < 0) {
          setOffset(0);
          buyChickenRef.current.scrollLeft = 0;
          setIsLocked(false); // Allow vertical scrolling after reaching the start
          document.body.classList.remove('no-scroll');
        } else if (newOffset > maxOffset) {
          setOffset(maxOffset);
          buyChickenRef.current.scrollLeft = maxOffset;
          setIsLocked(false); // Allow vertical scrolling after reaching the end
          document.body.classList.remove('no-scroll');
        }
      }
    };

    window.addEventListener('wheel', handleHorizontalScroll, { passive: false });
    return () => window.removeEventListener('wheel', handleHorizontalScroll);
  }, [isLocked, offset, maxOffset]);

  const sliderRef = React.useRef(null);

  const settings = {
    dots: false, // No dots, only arrows
    infinite: false,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1, // Scroll one card at a time
    arrows: false, // Hide default arrows
    swipe: true, // Enable swipe
    touchMove: true,
    responsive: [
      {
          breakpoint: 1000,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          },
      },
      {
          breakpoint: 500,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
      },
  ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  }

  const prev = () => {
    sliderRef.current.slickPrev();
  }

  return (
    <div>
      <Header />
      <section className="hero_section">
        <div className="container">
          <div className="land_main_row">
            <div className="hero_pos_here">
              <div className="hero_pos_abs">
                <div className="hero_main">
                  <span className="hero_wel">WELCOME</span>
                  <div className="hero_main_name">
                    <span className="Hero_name">VOLTRIX</span>
                    <span className="Hero_sub_name">
                      {" "}
                      Powering the Future of Finance
                    </span>
                  </div>
                </div>
                <div className="hero_sub_main">
                  Join us in revolutionizing finance with innovative blockchain <br/>
                  technology and sustainable growth.
                </div>
              </div>
              <img
                src={require("../images/Landing_hero_logo.png")}
                alt="voltrix logo"
                className="land_logo_img"
              />
            </div>
            <div class="lines-wrapper-container">
              <svg class="l1" width="833" height="245" viewBox="0 0 833 245">
                <path
                  id="l1"
                  d="M832 244L763 175H588L526 113H364L252 1H-100"
                ></path>
                <circle cx="0" cy="0" r="3" data-id="0">
                  <animateMotion dur="9.78582413667072s" repeatCount="indefinite">
                    <mpath href="#l1"></mpath>
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="3" data-id="1">
                  <animateMotion
                    dur="11.384990878543451s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l1"></mpath>
                  </animateMotion>
                </circle>
              </svg>
              <svg class="l2" width="814" height="250" viewBox="0 0 814 250">
                <path
                  id="l2"
                  d="M814 137H687L654 104H500L449 53H236L184 1H-86 M687 137L625 199H437L385.5 147.5H249L147 249.5H-57"
                ></path>
                <circle cx="0" cy="0" r="3" data-id="0">
                  <animateMotion
                    dur="9.546981527187508s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l2"></mpath>
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="3" data-id="1">
                  <animateMotion
                    dur="8.668278704305493s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l2"></mpath>
                  </animateMotion>
                </circle>
              </svg>
              <svg class="l3" width="815" height="126" viewBox="0 0 815 126">
                <path
                  id="l3"
                  d="M814 1H752L677 76H555C552 73 535 56 535 56C535 56 439 54.3333 391 56L322 125H240L217.5 102.5H-111 M814 1L766.5 48.5H705"
                ></path>
                <circle cx="0" cy="0" r="3" data-id="0">
                  <animateMotion
                    dur="9.472036875812387s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l3"></mpath>
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="3" data-id="1">
                  <animateMotion
                    dur="7.329442757984376s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l3"></mpath>
                  </animateMotion>
                </circle>
              </svg>
              <svg class="l4" width="816" height="143" viewBox="0 0 816 143">
                <path
                  id="l4"
                  d="M1 110H41L73 142H160L205.5 96.5H345L390.5 51H577L611 17H937 M345 96L379.5 130.5H489L517.5 102H851 M1 109.5L32.5 78H138L215 1H517L567.5 51.5"
                ></path>
                <circle cx="0" cy="0" r="3" data-id="0">
                  <animateMotion
                    dur="7.042430502367044s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l4"></mpath>
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="3" data-id="1">
                  <animateMotion
                    dur="10.053959125432916s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l4"></mpath>
                  </animateMotion>
                </circle>
              </svg>
              '
              <svg class="l5" width="833" height="129" viewBox="0 0 833 129">
                <path
                  id="l5"
                  d="M1 1L37 37H134L180 83H511L581.5 12.5H960 M511 83L556.5 128.5H626L661 93.5H919"
                ></path>
                <circle cx="0" cy="0" r="3" data-id="0">
                  <animateMotion
                    dur="8.865911339340864s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l5"></mpath>
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="3" data-id="1">
                  <animateMotion
                    dur="9.387851312727754s"
                    repeatCount="indefinite"
                  >
                    <mpath href="#l5"></mpath>
                  </animateMotion>
                </circle>
              </svg>
            </div>
          </div>

          {/* <div className="App">
      <svg width="478" height="595" viewBox="0 0 478 595" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          ref={rectRef}
          x="1"
          y="1"
          width="476"
          height="593"
          rx="26"
          stroke="#FFB627"
          strokeWidth="2"
          style={{
            filter: 'drop-shadow(0 0 5px rgba(255, 182, 39, 0.5))', // Adds a small box shadow around the line
            opacity: 0.8, // Makes the line a bit dim
          }}
        />
      </svg>
    </div> */}

        </div>
      </section>


      <div
        className="mb-5"
        style={{
          width: "100%",
          height: "1px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#282c34",
        }}
      >
        <svg width="100%" height="10">
          <defs>
            <filter id="glow">
              <feGaussianBlur stdDeviation="5.5" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <filter id="shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="rgba(255, 182, 39, 0.5)" />
              <feDropShadow dx="0" dy="0" stdDeviation="4" floodColor="rgba(255, 182, 39, 0.3)" />
              <feDropShadow dx="0" dy="0" stdDeviation="6" floodColor="rgba(255, 182, 39, 0.2)" />
            </filter>
          </defs>
          <line
            ref={lineRef}
            x1="0"
            y1="5"
            x2="100%"
            y2="5"
            stroke="#FFB627"
            strokeWidth="1"
            style={{
              filter: "url(#glow) url(#shadow)", // Adds multiple box shadows and a blur effect
              opacity: 0.4, // Makes the line a bit dim
            }}
          />  
        </svg>
      </div>

      <section className="about_section">
        <div className="container">
          <div className="about_main">
            <div className="abut_main_abs">
              <div className="about_bordiv_main">
                {/* SVG Border */}
                <svg className="count_borsvg_main" viewBox="0 0 100% 100%">
                  <defs>
                    <filter id="glow-and-shadow">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" result="blur" />
                      <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="rgba(255, 182, 39, 0.6)" />
                      <feDropShadow dx="0" dy="0" stdDeviation="4" floodColor="rgba(255, 182, 39, 0.4)" />
                      <feDropShadow dx="0" dy="0" stdDeviation="6" floodColor="rgba(255, 182, 39, 0.2)" />
                      <feMerge>
                        <feMergeNode in="blur" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>
                  <rect
                    ref={rectsecondRef}
                    x="1"
                    y="1"
                    rx="18"
                    className="animated-border-about"
                  />
                </svg>

                {/* Content Inside the Card */}
                <div className="count_in_content">
                  <span className="ff_36">About Voltrix</span>
                </div>
              </div>
            </div>
            <span className="about_span">
              Join the green revolution with Voltrix (VTX), We’re powering the future
              by funding renewable energy projects and building a vast network of EV
              charging stations in India. Our VTX Charging Stations deliver low-cost,
              eco-friendly power to electric vehicles, positioning us at the forefront
              of the booming EV market. Utilize our VTX tokens at our stations to receive
              charging bonuses, enhancing value for our investors. Invest in VTX tokens to become a
              partner in this exciting venture and share in the profits as we expand to over 500 stations.
              Be a part of the energy transformation and drive the future with Voltrix!
            </span>
          </div>
        </div>
      </section>

      <section className="tokeno_section">
        <div className="container">
          <div className="count_main">
            <div className="tokeno_top_main">
              <div className="hero_main_name">
                <span className="ff_40"> Voltrix Tokenomics</span>
                <span className="color_18">  Transparent allocation and strategic planning ensure long-term value.</span>
              </div>
            </div>
            <div className="row token_row">
              <div className="col-lg-5 pl-0 d-flex justify-content-center">
                <div className="tokeno_main_dimen">
                  <img src={require('../images/1.png')} className="tokeno_left_frst rotate" />
                  <img src={require('../images/2.png')} className="tokeno_left_frst rotate" />
                  <img src={require('../images/3.png')} className="tokeno_left_frst rotate" />
                  <img src={require('../images/3.png')} className="tokeno_left_frst rotate" />
                <img src={require('../images/4.png')} className={imageClass('4')} data-target="4" />
                <img src={require('../images/5.png')} className={imageClass('5')} data-target="5" />
                <img src={require('../images/6.png')} className={imageClass('6')} data-target="6" />
                <img src={require('../images/7.png')} className={imageClass('7')} data-target="7" />
                <img src={require('../images/8.png')} className={imageClass('8')} data-target="8" />

                  <img src={require('../images/tokenomics.gif')} className="dojs" width="50%" />
                  <div className="tokeno_center_letters">
                    <span className="ff_28">
                    1,500,000,000
                    </span>
                    <span className="ff_20">
                    Voltrix Tokens
                    </span>
                  </div>

                  <audio id="sound" className="d-none" controls preload="auto"><source src={require("../images/videoplayback.mp3")} controls></source></audio>

                </div>

              </div>
              <div className="col-lg-7 pr-0 mt-4">
                <div className="tokeno_table_main">
                  <div className="table-responsive table-borderless table_res">
                    <table className="custom-table">
                      <thead>
                        <th className="th-1">Sale Round</th>
                        <th className="th-2">Voltrix Tokens</th>
                        <th className="th-3">Supply Percent</th>
                        <th className="th-4">Token Price</th>
                      </thead>
                      <tbody>
                        <tr onMouseEnter={() => handleMouseEnter(['4', '5', '8', '7'])}
                          onMouseLeave={handleMouseLeave}>
                          <td className="frst_table_border">
                            <div className="tokeno_tabl_frst">
                              <div className="tokeno_tabl_frstcolor"></div>
                              <span className="land_header_leftmenus">Public Sale</span>
                            </div>
                          </td>
                          <td>  <span className="land_header_leftmenus">
                          600000000
                          </span></td>
                          <td>  <span className="land_header_leftmenus">
                            40%
                          </span></td>
                          <td className="last_table_border">  <span className="land_header_leftmenus">
                            $0.00
                          </span></td>
                        </tr>
                        <tr onMouseEnter={() => handleMouseEnter(['4', '5', '8', '6'])}
                          onMouseLeave={handleMouseLeave}>
                          <td className="frst_table_border">
                            <div className="tokeno_tabl_frst">
                            <div className="tokeno_tabl_scndcolor"></div>
                            <span className="land_header_leftmenus">Ecosystem Fund</span>
                            </div>
                          </td>
                          <td>  <span className="land_header_leftmenus">
                          300000000
                          </span></td>
                          <td>  <span className="land_header_leftmenus">
                            20%
                          </span></td>
                          <td className="last_table_border">  <span className="land_header_leftmenus">
                            $0.00
                          </span></td>
                        </tr>
                        <tr onMouseEnter={() => handleMouseEnter(['4', '5', '6', '7'])}
                          onMouseLeave={handleMouseLeave}>
                          <td className="frst_table_border">
                            <div className="tokeno_tabl_frst">
                            <div className="tokeno_tabl_thrdcolor"></div>
                            <span className="land_header_leftmenus">Air Drop & Rewards</span>
                            </div>
                          </td>
                          <td>  <span className="land_header_leftmenus">
                          225000000
                          </span></td>
                          <td>  <span className="land_header_leftmenus">
                            15%
                          </span></td>
                          <td className="last_table_border">  <span className="land_header_leftmenus">
                            $0.00
                          </span></td>
                        </tr>
                        <tr onMouseEnter={() => handleMouseEnter(['6', '5', '8', '7'])}
                          onMouseLeave={handleMouseLeave}>
                          <td className="frst_table_border">
                            <div className="tokeno_tabl_frst">
                              <div className="tokeno_tabl_frthcolor"></div>
                              <span className="land_header_leftmenus">Reserve Fund</span>
                            </div>
                          </td>
                          <td>  <span className="land_header_leftmenus">
                          150000000
                          </span></td>
                          <td>  <span className="land_header_leftmenus">
                            10%
                          </span></td>
                          <td className="last_table_border">  <span className="land_header_leftmenus">
                            $0.00
                          </span></td>
                        </tr>
                        <tr onMouseEnter={() => handleMouseEnter(['4', '6', '8', '7'])}
                          onMouseLeave={handleMouseLeave}>
                          <td className="frst_table_border">
                            <div className="tokeno_tabl_frst">
                              <div className="tokeno_tabl_fthcolor"></div>
                              <span className="land_header_leftmenus">Team & Advisors</span>
                            </div>
                          </td>
                          <td>  <span className="land_header_leftmenus">
                          225000000
                          </span></td>
                          <td>  <span className="land_header_leftmenus">
                            15%
                          </span></td>
                          <td className="last_table_border">  <span className="land_header_leftmenus">
                            $0.00
                          </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tokeno_top_main mt-5">
             <div className="hero_main_name">
                <span className="ff_40 mt-5"> Contract Address </span>
                <a href="https://bscscan.com/address/0x55ae36e761ecdc22b81f4bb903ac05ccf0a65edb" target="_blank">
                <div className="contract_main">
                  <span className="contract">0x55aE36E761ECdc22b81F4bB903AC05cCf0A65eDB</span>
                </div>
                </a>
              </div>
          </div>
        </div>
      </section>

      <section className="roadmap_section"
      //  ref={buyChickenRef}
      >
        <div className="container overflow-hidden">
          <div className="road_main_back">
            <div className="hero_main_name">
              <span className="ff_40">Roadmap</span>
              <span className="color_18">
                Clear milestones guide our journey with a focus on innovation,<br /> transparency, and community-driven growth.
              </span>
            </div>
            {/* <div   className=" road_cards_main parallax-text left-to-right d-flex"
              style={{ transform: `translateX(-${offset}px)`}}>
              <div className="road_card">
                <div className="count_main">
                  <span className="road_card_phase">
                    Phase 1
                  </span>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">ICO Launch</span>
                    <span className="land_header_leftmenus">  The ICO Launch kicks off our journey by introducing our token to the public. This phase focuses on generating initial funding, building awareness, and engaging early supporters to create a strong foundation for future growth.</span>
                  </div>
                </div>
              </div>
              <div className="road_card">
                <div className="count_main">
                  <span className="road_card_phase">
                    Phase 2
                  </span>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Exchange</span>
                    <span className="land_header_leftmenus"> In this phase, our token gets listed on major exchanges to increase liquidity and accessibility. This step enhances market presence, allowing users to trade tokens easily and boosting overall adoption and visibility.</span>
                  </div>
                </div>
              </div>
              <div className="road_card">
                <div className="count_main">
                  <span className="road_card_phase">
                    Phase 3
                  </span>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Own Blockchain</span>
                    <span className="land_header_leftmenus">In this phase, we transition to our own blockchain to enhance security, scalability, and independence. This development allows us to tailor our blockchain to better meet our project’s specific needs, offering improved performance and greater control.</span>
                  </div>
                </div>
              </div>
              <div className="road_card">
                <div className="count_main">
                  <span className="road_card_phase">
                    Phase 4
                  </span>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">DEX</span>
                    <span className="land_header_leftmenus"> In this phase, we launch our Decentralized Exchange (DEX) to provide a secure, transparent, and efficient trading environment. Our DEX enables peer-to-peer trading of tokens with lower fees and increased privacy, further decentralizing the ecosystem and empowering users.</span>
                  </div>
                </div>
              </div>
              <div className="road_card">
                <div className="count_main">
                  <span className="road_card_phase">
                    Phase 5
                  </span>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Carbon Credit Offset Strategy</span>
                    <span className="land_header_leftmenus"> In this phase of our ICO token project, we implement a strategy to offset carbon emissions entirely without relying on carbon credits. This approach underscores our commitment to environmental sustainability by reducing our carbon footprint and promoting eco-friendly practices within the blockchain ecosystem.</span>
                  </div>
                </div>
              </div>
            </div> */}
   <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        <div className="road_card">
        <div className="count_main">
                  <div className="road-icon-main">
                    <img src={require("../images/road-1.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 1
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Token Launch</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Kickstart the Future</span>
                    <span className="land_header_leftmenus"> Get ready for the launch of our innovative token. This initial step marks the beginning of our journey and opens up exciting opportunities for early adopters.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-2.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 2
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Launch Our Exchange</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18"> Trade with Ease</span>
                    <span className="land_header_leftmenus"> Introducing our own exchange platform where you can seamlessly trade using VTX tokens. Enjoy an optimized trading experience with direct access to our ecosystem.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-3.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 3
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff"> Coin Listing (IEO)</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Join the Movement</span>
                    <span className="land_header_leftmenus"> Participate in our Initial Exchange Offering (IEO) to secure your tokens early and be a part of our growing community.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-4.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 4
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Advanced Trading Tool and Market Analysis</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Empower Your Trades</span>
                    <span className="land_header_leftmenus">Utilize cutting-edge tools and in-depth market analysis to make informed trading decisions and optimize your strategy.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-5.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 5
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Optional Trading</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18"> Flexible Options</span>
                    <span className="land_header_leftmenus">Explore various trading options tailored to your preferences, enhancing your trading experience with flexibility and control.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-6.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 6
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff"> Web3 Wallet (Multi-Chain Support)</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Secure and Versatile</span>
                    <span className="land_header_leftmenus"> Manage your assets effortlessly with our Web3 wallet, offering multi-chain support for a diverse range of cryptocurrencies.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-7.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 7
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">  Game <br/>
                    [Play with Multi-Currency, Get More Native Tokens]</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Play and Earn</span>
                    <span className="land_header_leftmenus">  Engage in our interactive game to earn native tokens. Enjoy the fun and reward yourself with additional tokens through gameplay.</span>

                    </div>
                  </div>
                </div>
        </div>
        <div className="road_card">
        <div className="count_main">
        <div className="road-icon-main">
        <img src={require("../images/road-8.png")} alt="road" className="road_chn_ico" />
                  <span className="road_card_phase">
                    Phase 8
                  </span>
                  </div>
                  <div className="gap_24_cl">
                    <span className="fnt_24_ff">Support Green Energy Using EV Station</span>
                    <div className="unlock_chan_inner">
                      <span className="ff_18">Go Green with Us</span>
                    <span className="land_header_leftmenus">  Support sustainable energy by integrating our token with EV charging stations. Contribute to a greener future while benefiting from our innovative approach.</span>

                    </div>
                  </div>
                </div>
        </div>
      </Slider>
      <div className="arrow-container mt-5">
        <div className="road_chang_arrow" onClick={prev}>
        <svg xmlns="http://www.w3.org/2000/svg" className="roas_chan_arrimg" viewBox="0 0 32 32" id="LeftArrow"><path d="M20.2 9.804a1 1 0 0 0-1.414 0L12.3 16.29a.997.997 0 0 0-.292.71c0 .258.096.514.292.71l6.486 6.486a1 1 0 0 0 1.414-1.414L14.418 17l5.782-5.782a1 1 0 0 0 0-1.414zM17 2C8.716 2 2 8.716 2 17s6.716 15 15 15 15-6.716 15-15S25.284 2 17 2zm0 28C9.832 30 4 24.168 4 17S9.832 4 17 4s13 5.832 13 13-5.832 13-13 13z" fill="#ffffff" class="color000000 svgShape"></path></svg>
        </div>
        <div className="road_chang_arrow" onClick={next}>
        <svg xmlns="http://www.w3.org/2000/svg" className="roas_chan_arrimg" viewBox="0 0 32 32" id="RightArrow"><path d="M21.7 16.29l-6.486-6.486a1 1 0 0 0-1.414 1.414L19.582 17 13.8 22.782a1 1 0 0 0 1.414 1.414L21.7 17.71a.992.992 0 0 0 .292-.71.997.997 0 0 0-.292-.71zM17.072 2.144c-8.244 0-14.928 6.684-14.928 14.928S8.828 32 17.072 32 32 25.316 32 17.072 25.316 2.144 17.072 2.144zm0 27.856C9.944 30 4.144 24.2 4.144 17.072s5.8-12.928 12.928-12.928S30 9.944 30 17.072 24.2 30 17.072 30z" fill="#ffffff" class="color000000 svgShape"></path></svg>
        </div>
      </div>
    </div>

          </div>
        </div>
      </section>

      <section className="key_feature">
        <div className="container">
          <div className="count_main_row">
            <div className="count_main">
            <div className="hero_main_name">
                  <span className="ff_40">
                  Key Features
                  </span>
                  <span className="color_18">
                  Explore the Exceptional Features That Set Us Apart
                  </span>
                </div>
                <div className="row count_main_row">
                  <div className="col-lg-4 mt-2">
                    <div className="key_inner_main">
                        <div className="hero_main_name text-align-center">
                          <span className="ff_20">
                          Blockchain Integration
                          </span>
                          <span className="unlock_16_top">
                          Secure and transparent transactions powered 
by our blockchain platform.
                          </span>
                        </div>
                        <img src={require("../images/blockchain.png")} alt="Blockchain image" className="key_in_img" />
                        <img src={require("../images/Group 80.png")} alt="key backgrnd" className="key_img_abs" />
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2">
                    <div className="key_inner_main">
                        <div className="hero_main_name text-align-center">
                          <span className="ff_20">
                          Renewable Energy Utilization
                          </span>
                          <span className="unlock_16_top">
                          Charging stations powered by solar, wind, and other renewable sources.
                          </span>
                        </div>
                        <img src={require("../images/Renewable energy.png")} alt="Blockchain image" className="key_in_img" />
                        <img src={require("../images/Group 80.png")} alt="key backgrnd" className="key_img_abs" />
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2">
                    <div className="key_inner_main">
                        <div className="hero_main_name text-align-center">
                          <span className="ff_20">
                          Advanced Token Ecosystem
                          </span>
                          <span className="unlock_16_top">
                          Access exclusive benefits and rewards through our Voltrix tokens.
                          </span>
                        </div>
                        <img src={require("../images/token.png")} alt="Blockchain image" className="key_in_img" />
                        <img src={require("../images/Group 80.png")} alt="key backgrnd" className="key_img_abs" />
                    </div>
                  </div>
                </div>
            </div>
          </div>   
        </div>
      </section>


      <section className="unlock_section">
        <div className="container">
          <div className="unlock_main">
            <div className="hero_main_name">
              <span className="ff_40">Unlock Early Access to VTX Tokens</span>
              <span className="color_18">
                Empowering Growth and Participation Across All Phases
              </span>
            </div>
            <div className="count_main_row">
            <div className="unlock_bordiv_main">
                  {/* SVG Border */}
                  <svg className="count_borsvg_main" viewBox="0 0 100% 100%">
                    <defs>
                      <filter id="glow-and-shadow">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" result="blur" />
                        <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="rgba(255, 182, 39, 0.6)" />
                        <feDropShadow dx="0" dy="0" stdDeviation="4" floodColor="rgba(255, 182, 39, 0.4)" />
                        <feDropShadow dx="0" dy="0" stdDeviation="6" floodColor="rgba(255, 182, 39, 0.2)" />
                        <feMerge>
                          <feMergeNode in="blur" />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    <rect
                      ref={rectunlockRef}
                      x="1"
                      y="1"
                      rx="18" 
                      width="99.9%"
                      height="99.7%"
                      className="animated-border-unlock"
                    />
                  </svg>

                  {/* Content Inside the Card */}
                  <div className="unlock_inchange_content">
                    {/* <h1 style={{ margin: '0 0 20px' }}>Countdown Timer</h1>
                            <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                              {countdown} seconds
                            </div> */}

                    <img src={require('../images/Unlock-content-img.png')} alt="Unlock Voltrix" className="unlock_content_img" />

                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section className="about_section">
        <div className="container">
          <div className="about_main">
            <div className="abut_main_abs">
              <div className="about_bordiv_main">
                {/* SVG Border */}
                <svg className="count_borsvg_main" viewBox="0 0 100% 100%">
                  <defs>
                    <filter id="glow-and-shadow">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" result="blur" />
                      <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="rgba(255, 182, 39, 0.6)" />
                      <feDropShadow dx="0" dy="0" stdDeviation="4" floodColor="rgba(255, 182, 39, 0.4)" />
                      <feDropShadow dx="0" dy="0" stdDeviation="6" floodColor="rgba(255, 182, 39, 0.2)" />
                      <feMerge>
                        <feMergeNode in="blur" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>
                  <rect
                    ref={rectsecondfrstRef}
                    x="1"
                    y="1"
                    rx="18"
                    className="animated-border-about"
                  />
                </svg>

                {/* Content Inside the Card */}
                <div className="count_in_content">
                  <span className="ff_36">Burn Mechanism</span>
                </div>
              </div>
            </div>
            <span className="about_span">
            The VTX token features a token burning mechanism 
            to help increase its value. Every time VTX tokens 
            are circulated, 30% of them are permanently removed 
            from circulation. By reducing the total supply of VTX
             tokens in the market, the scarcity of the tokens increases. 
             As the supply decreases, the demand for VTX tokens grows, 
             making them more valuable over time. 
             This mechanism ensures that VTX token holders benefit 
             from a stronger and more valuable digital asset.
            </span>
          </div>
        </div>
      </section>
      <section className="community_section">
        <div className="container">
          <div className="community_main">
            <div className="count_main">
              <div className="hero_main_name">
                <span className="ff_40"> Join Our Community</span>
                <span className="color_18">
                  Empowering a Sustainable Future Together
                </span>
              </div>
              <div className="community_chang_border">
              <div className="community_box">
                <div className="comm_inside_gap">
                  <div className="hero_pos_abs">
                    <div className="community_platform">
                      <img src={require('../images/youtube-logo.png')} alt="youtube" className="community_you_image" />
                      <span id="youtube-text" className="hover_text_you">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">YOUTUBE</span>
                  </div>
                  <div className="hero_pos_abs">
                    <div className="community_platform_one">
                      <img src={require('../images/Twitter-logo.png')} alt="TWITTER" className="community_platform_images" />
                      <span className="hover_text_twi">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">TWITTER</span>
                  </div>
                  <div className="hero_pos_abs">
                    <div className="community_platform_two">
                      <img src={require('../images/Facebook-logo.png')} alt="FACEBOOK" className="community_platform_images" />
                      <span className="hover_text_face">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">FACEBOOK</span>
                  </div>
                  <div className="hero_pos_abs"
                  // onMouseEnter={() => handlebuttonsAudio()} onMouseEnter={() => handlebuttonsAudio()}
                   >
                    <div className="community_platform_three">
                      <img src={require('../images/Telegram-logo.png')} alt="TELEGRAM" className="community_platform_images" />
                      <span className="hover_text_tele">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">TELEGRAM</span>
                  </div>
                </div>
                <div className="comm_inside_gapscnd">
                  <div className="hero_pos_abs">
                    <div className="community_platform_four">
                      <img src={require('../images/Linkedin-logo.png')} alt="linkedin" className="community_platform_images" />
                      <span className="hover_text_lin">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">LINKEDIN</span>
                  </div>
                  <div className="hero_pos_abs">
                    <div className="community_platform_five">
                      <img src={require('../images/Instagram-logo.png')} alt="instagram" className="community_platform_images" />
                      <span className="hover_text_insta">JOIN NOW </span>
                    </div>
                    <span className="ffc630_14">Instagram</span>
                  </div>
                </div>
              </div>
              </div>
              
            </div>
            <div className="count_main">
              <span className="ff_40">Power Up with Voltrix <br/>
              for a Sustainable Future</span>
              <Link to="/buy">
              <div className="count_buy_main">
                <span >Buy Now</span>
              </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
      <section className="footer_section">
        <div className="container">
          <div className="footer_main">
            <span className="footer_text">
            © VOLTRIX, 2024. All rights reserved.
            </span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Landing;
